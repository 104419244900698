import React from "react";

import { useFormattedDateTime, useTranslate } from "../../utils/utils";
import style from "./style.module.css";

const MetaData = ({ category, startTimeMs, seasons, typeBanner }) => {
  const t = useTranslate();
  const formattedDate = useFormattedDateTime(startTimeMs, typeBanner);

  return (
    <section className={style.metaData}>
      {!!category && <span className={style.xenero}>{category}</span>}
      {!!startTimeMs && <span className={style.fecha}> | {formattedDate}</span>}
      {seasons && <span className={style.temporadas}>{t("ficha.seasons", { seasons: seasons })}</span>}
    </section>
  );
};

export default MetaData;
