import React from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
  TENANT_PETISGO,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";

const useStyles = makeStyles(() => ({
  iconContainer: {
    zIndex: 0,
  },
}));

const UserButtonOptionsComponent = ({
  handlePasswordModal,
  handleDisplayOnBoarding,
  handleTermsModal,
  checkoutURL,
  terms,
  tutorialUrl,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleRedirectToTutorial = () => {
    window.location.href = tutorialUrl;
  };

  return (
    <Col md={12}>
      <Row className={"OptionsContainer"}>
        <h2 className={"Title-bold"}>{t("perfil.otras.opciones")}</h2>
      </Row>
      <Row className={"OptionButtonsRow"}>
        {(!!tutorialUrl || !!MULTITENANT_CONSTANTS[TENANT].showOnBoarding) && (
          <div
            className={"ProfileOptionButton"}
            onClick={() =>
              !!tutorialUrl
                ? handleRedirectToTutorial()
                : handleDisplayOnBoarding(false)
            }
          >
            <div className={classes.iconContainer}>
              <ion-icon name="eye" />
            </div>
            <span>{t("perfil.watch.tutorial")}</span>
          </div>
        )}
        {!!terms && (
          <div
            className={"ProfileOptionButton"}
            onClick={() => handleTermsModal(true)}
          >
            <div className={classes.iconContainer}>
              <ion-icon name="reader" />
            </div>
            <span>{t("perfil.read.terms")}</span>
          </div>
        )}
        {MULTITENANT_CONSTANTS[TENANT].showSubscriptions && (
          <div
            className={"ProfileOptionButton"}
            onClick={() => window.open(checkoutURL)}
          >
            <div className={classes.iconContainer}>
              <ion-icon name="cart" />
            </div>
            <span>{t("perfil.watch.subscriptions")}</span>
          </div>
        )}
        <div
          className={"ProfileOptionButton"}
          onClick={() => handlePasswordModal(true)}
        >
          <div className={classes.iconContainer}>
            <ion-icon name="lock-closed" />
          </div>
          <span>{t("perfil.update.password")}</span>
        </div>
      </Row>
    </Col>
  );
};

export default UserButtonOptionsComponent;
