import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import moment from "moment";
import DatePicker from "react-datepicker";

import { ROL_KID, ROL_TEEN, ROL_ADULT } from "./PantallasRexistro";

import RegisterNavigationComponent from "../common/components/RegisterNavigationComponent";

function SelectAge({ asignarRole, ctrlSetBirthday, datos }) {
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [ageSelected, setAgeSelected] = useState(false);
  const [followButtonDisabled, setFollowButtonDisabled] = useState(true);

  const minorThan = 6;

  const [dayLimit, setDayLimit] = useState(null);
  const [monthLimit, setMonthLimit] = useState(null);
  const [yearLimit, setYearLimit] = useState(null);

  const resetBirthDay = () => {
    ctrlSetBirthday("1111-01-01");
  };

  const userRol = () => {
    if (ageSelected?.years < 10) {
      asignarRole(ROL_KID);
    } else {
      if (ageSelected?.years < 14) {
        asignarRole(ROL_TEEN);
      } else {
        asignarRole(ROL_ADULT);
      }
    }
    resetBirthDay();
    !!ageSelected && ctrlSetBirthday(ageSelected?.birthday);
  };

  const handleChangeData = (d) => {
    if (d) {
      setData(d);
      let ds = moment(d).format("YYYY-MM-DD");
      let today = moment();
      let years = today.diff(ds, "years");
      setAgeSelected({ years, birthday: ds });
      setFollowButtonDisabled(false);
    }
  };

  useEffect(() => {
    const today = new Date();
    setMonthLimit(today.getUTCMonth()); //months from 1-12
    setDayLimit(today.getUTCDate());
    setYearLimit(today.getUTCFullYear() - minorThan);
  }, []);

  return (
    <div className={"selectAgeContainer"}>
      <div className={"selectAge"}>
        <div className={"agePageTitle"}>
          <h3>{t("register.birthDate")}</h3>
        </div>

        <div className="campoForm campoData">
          <DatePicker
            id="dataNacemento"
            dateFormat="dd/MM/yyyy"
            selected={data}
            placeholderText={t("register.datadata")}
            onChange={(date) => handleChangeData(date)}
            maxDate={new Date(yearLimit, monthLimit, dayLimit)}
            showYearDropdown
            showMonthDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            popperClassName={"popperDatePicker"}
            autoComplete={"off"}
          />
        </div>
        <RegisterNavigationComponent
          handlerNextPage={userRol}
          followButtonDisabled={followButtonDisabled}
        />
      </div>
    </div>
  );
}

SelectAge.propTypes = {
  asignarRole: PropTypes.func,
};

export default SelectAge;
