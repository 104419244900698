import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "./Footer.css";
import packageJson from "@app/../package.json";
import { useTranslation } from "react-i18next";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../Resources/Multitenant/tenantConstants";
import { URL_BASE, URL_REM } from "../../../services/servicesConstants";

function Footer() {
  const supportEmail = MULTITENANT_CONSTANTS[TENANT]?.supportEmail;
  const { interfaceUrls } = useSelector((state) => state.commonState);
  const { sponsors } = useSelector((state) => state.commonState);
  const { t } = useTranslation();

  const baseUrl = URL_BASE;

  return (
    <div className="Footer">
      <div className="FooterBody">
        {sponsors &&
          sponsors.map((a, key) => (
              a?.sponsors_id?.logo?.private_hash &&
            <div key={`${a.sponsors_id.company}-${key}`} className="ImageDiv">
              <Link
                to={""}
                onClick={() =>
                  a.sponsors_id.onclickurl !== null ? window.open(a.sponsors_id.onclickurl, "_blank") : undefined
                }
              >
                <img
                  className="ImageEurope"
                  src={`${baseUrl}${URL_REM}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/assets/${a?.sponsors_id?.logo?.private_hash}`}
                  alt={"logos"}
                />
              </Link>
            </div>
          ))}
        <span className="versionTag">{packageJson.version}</span>
      </div>
      {!!supportEmail && (
        <div className={"footerLink"}>
            <div>
                <a href={`mailto:${supportEmail}`} target={"_blank"} rel="noreferrer">
                        {t("suport.contact")} {supportEmail}
                    </a>
            </div>
        </div>
          )}

        <div className={"footerLink"}>
        {!!MULTITENANT_CONSTANTS[TENANT]?.urlFaqs && (
            <div>
                <a href={MULTITENANT_CONSTANTS[TENANT]?.urlFaqs} target={"_blank"} rel="noreferrer">
                        {t("suport.faqs")}
                    </a>
            </div>
            )}
            {(interfaceUrls.termsAndConditionsUrl || !!MULTITENANT_CONSTANTS[TENANT]?.urlTermsAndConditions)  && (
          <div>
              <a href={interfaceUrls?.termsAndConditionsUrl ? interfaceUrls.termsAndConditionsUrl : MULTITENANT_CONSTANTS[TENANT]?.urlTermsAndConditions}
                 target={"_blank"} rel="noreferrer">
                  {t("legal.termsAndConditions")}
              </a>
          </div>
        )}
          {(interfaceUrls.privacy_url || !!MULTITENANT_CONSTANTS[TENANT]?.urlPrivacyStatement) && (
              <div>
                  <a href={interfaceUrls?.privacyStatementUrl ? interfaceUrls.privacyStatementUrl : MULTITENANT_CONSTANTS[TENANT]?.urlPrivacyStatement}
                     target={"_blank"} rel="noreferrer">
                      {t("legal.privacyStatement")}
                  </a>
              </div>
          )}
          <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" className="cky-banner-element">
                  {t("suport.cookies")}
              </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
