import React, { useState, useEffect } from "react";
import { TRANSITION_DURATION } from "../PlaylistConstants";

import defaultBanner from "@app/Resources/Images/default_banner.png";
import Carousel from "react-multi-carousel";

import { responsive } from "@detail/Pages/DetailPage/CarrouselStyle";

import { LIMIT_ITEMS } from "./utils";
import Spinner from "react-bootstrap/Spinner";

import CommonLiveCardComponent from "./LiveCardsComponents/CommonLiveCardComponent";

const LiveWidgetComponent = (props) => {
  const {
    className,
    style,
    title,
    typeBanner,
    playlist,
    playSpinner,
    trigger,
    clickCallback,
    titleColor,
  } = props;

  const [overOnCard, setOverOnCard] = useState(false);
  const [limitNumberCards, setLimitNumberCards] = useState(LIMIT_ITEMS);

  const minResolutionToCarousel = 14000;
  useEffect(() => {
    if (window.innerWidth < minResolutionToCarousel) {
      setLimitNumberCards(3);
    }
  }, [playlist]);

  const minimumItems =
    +playlist?.length < limitNumberCards ? " BlockCarousel" : " ";

  return (
    <>
      <div className="CarouselContainer">
        <div className="PlaylistContainer">
          <div className={"Playlist " + className} style={style}>
            <div className="Titulo" style={{ display: "flex" }}>
              <p style={{ width: "auto", color: titleColor }}>{title}</p>
            </div>
            {!!playlist?.length && (
              <Carousel
                swipeable={true}
                draggable
                focusOnSelect={false}
                showDots={false}
                responsive={responsive}
                infinite={false}
                autoPlay={false}
                keyBoardControl={false}
                transitionDuration={TRANSITION_DURATION}
                customTransition="transform 1000ms ease-in-out 0s"
                containerClass={
                  "carousel-container PlaylistCollection " +
                  typeBanner +
                  " " +
                  minimumItems
                }
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px CardContainer"
                centerMode={false}
              >
                {playlist?.map((w, i) => (
                  <div key={w.id}>
                    <div>{w.title}</div>
                    <CommonLiveCardComponent
                      w={w}
                      i={i}
                      overOnCard={overOnCard}
                      playlist={playlist}
                      setOverOnCard={setOverOnCard}
                      trigger={trigger}
                      clickCallback={clickCallback}
                      defaultBanner={defaultBanner}
                    />
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        </div>
        {playSpinner && (
          <div className="SpinnerContainer">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
    </>
  );
};

export default LiveWidgetComponent;
