import React from "react";
import PropTypes from "prop-types";

import style from "./style.module.css";
import ButtonShowMore from "../ButtonShowMore/ButtonShowMore";

const FrameworkTitle = ({ backgroundImage, backgroundColor, title, titleColor, id, hasMoreContents }) => {
  const renderTitle = <p>{title}</p>;

  const renderFrameworkHomeBackground = (
    <div
      className={style.frameworkTitleBackground}
      style={{
        backgroundImage: `url(${backgroundImage?.url})`,
        backgroundColor,
      }}
    >
      <p className={style.frameworkTitle} style={{ color: titleColor }}>
        {title}
      </p>
    </div>
  );

  return (
    <div className={style.titulo}>
      {!backgroundImage && !backgroundColor ? renderTitle : renderFrameworkHomeBackground}
      {hasMoreContents && <ButtonShowMore id={id} />}
    </div>
  );
};

FrameworkTitle.propTypes = {
  backgroundImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  backgroundColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  hasMoreContents: PropTypes.bool.isRequired,
};

FrameworkTitle.defaultProps = {
  hasMoreContents: false,
};

export default FrameworkTitle;
