import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { getSectionName } from "../../../../../Utils/utils";

const MenuSectionsComponent = ({ sections, changeSection, getIconSection, reloadIconFlag }) => {
  useEffect(() => {}, [reloadIconFlag]);

  return (
    <div>
      {!!sections
        ? sections.map((section, index) =>
            section?.section.screenId ? (
              <OverlayTrigger
                key={index}
                delay={{ hide: 450, show: 300 }}
                placement="bottom"
                overlay={(props) => <Tooltip {...props}>{getSectionName(section.section)}</Tooltip>}
              >
                <div
                  key={section?.section.iconId}
                  onClick={() => changeSection(section.section)}
                  className={`MenuSection ${section.section.iconId}`}
                >
                  {getIconSection(section.section?.iconsvg || section.section.iconId)}
                </div>
              </OverlayTrigger>
            ) : section.section.type.name$ === "UNKNOWN" ? (
              <></>
            ) : (
              <OverlayTrigger
                key={index}
                delay={{ hide: 450, show: 300 }}
                placement="bottom"
                overlay={(props) => <Tooltip {...props}>{getSectionName(section.section)}</Tooltip>}
              >
                <div
                  onClick={() => changeSection(section.section)}
                  className={`MenuSection ${section.section.iconId}`}
                  key={section.section.iconId}
                >
                  {getIconSection(section.section?.iconsvg || section.section.iconId)}
                </div>
              </OverlayTrigger>
            ),
          )
        : ""}
    </div>
  );
};

export default MenuSectionsComponent;
