import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Playlist from "../../../../detail/src/Pages/DetailPage/Playlist";
import {
  selectMenuScreenId,
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import {
  LOCAL_STORAGE_KEY__SECTION_ID,
  LOCAL_STORAGE_KEY__SECTION_URL,
  ORIGIN_SECTIONS,
  TYPE_BANNER_CONVERSION,
  WIDGET_ACTION_DETAIL,
  WIDGET_ACTION_FULL_LIST,
  WIDGET_TYPE_GRID,
  WIDGET_TYPE_GRID_GROUP,
  WIDGET_TYPE_GROUP_WIDGET,
  WIDGET_TYPE_HIGHLIGHT,
  widgetTypesWithoutSeparateSpace,
} from "../../../../app/src/Utils/Constants";
import {
  getBannersWidgetServices,
  getWidgetsServices,
} from "../../../../app/src/services/services";
import { TYPE_BANNER_FEATURED } from "../../../../detail/src/Pages/DetailPage/PlaylistConstants";
import { URL_HOME } from "../../../../app/src/Components/Routes/Location";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import { getWidgetTitle } from "../../../../app/src/Utils/utils";

const SectionComponent = (props) => {
    const {scrollRef} = props;

    const dispatch = useDispatch();
  const [sectionData, setSectionData] = useState([]);
  const [responseSectionData, setResponseSectionData] = useState(null);
  let isMounted = true;
  const history = useHistory();

  const { sectionSelected, sectionsMenu } = useSelector(
    (state) => state.commonState
  );

  useEffect(() => {
    !!sectionsMenu?.length && urlControl();
  }, [sectionsMenu]);

  const getSectionByPathname = () => {
    const parts = window.location.pathname.split("/");
    const screenId = parts[parts.length - 1];
    dispatch(selectMenuScreenId(screenId));
    return screenId;
  };

  useEffect(() => {
    urlControl();
    return () => {
      isMounted = false;
    };
  }, [sectionSelected]);

  const callbackError = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY__SECTION_URL);
    localStorage.removeItem(LOCAL_STORAGE_KEY__SECTION_ID);
    history.push(URL_HOME);
  };

  const urlControl = () => {
    const screenId = getSectionByPathname();
    getWidgetsServices(screenId, callbackGetWidgets);
  };

  const callbackGetWidgets = (dataWidgets) => {
    if (!!dataWidgets.widgets) {
      setResponseSectionData(dataWidgets);
      scrollRef?.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    } else {
      callbackError();
    }
  };

  useEffect(() => {
    if (!!responseSectionData) {
      Promise.all(
        responseSectionData.widgets.map((widget) =>
          creatObjectSectionToPrint(widget)
        )
      )
        .then((objectToPrintArray) => {
          setSectionData(objectToPrintArray);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [responseSectionData]);

  function widgetBannersData(widgetId) {
    return new Promise((resolve) => {
      getBannersWidgetServices(widgetId, (dataBanners) => {
        resolve(creatObjectToPrint(dataBanners, (data) => resolve(data)));
      });
    });
  }

  const convertBannerType = (dataBannerType) => {
    return dataBannerType === TYPE_BANNER_FEATURED
      ? WIDGET_TYPE_HIGHLIGHT
      : dataBannerType;
  };

  const creatObjectSectionToPrint = async (dataWidget) => {
    let widgetList = {};
    if (!!dataWidget) {
      const { widgets_id } = dataWidget;
      widgetList = {
        id: widgets_id?.id,
        titulo: widgets_id?.title,
        lista: await widgetBannersData(widgets_id?.id)
          .then((bannerList) => {
            return bannerList;
          })
          .catch((error) => {
            console.error("Error:", error);
          }),
        background: widgets_id.backgroundImage,
        backgroundColor: widgets_id.backgroundColor,
        titleColor: widgets_id.textColor,
        type: convertBannerType(widgets_id.type.toUpperCase()),
        rotateTime: null,
        access: null,
        hasMoreContents: null,
        loadState: null,
      };
    }
    return widgetList;
  };

  const creatObjectToPrint = (databanners) => {
    let creatingData = [];
    !!databanners &&
      databanners.map(
        (banner) =>
          (creatingData = [...creatingData, createObjectBanner(banner)])
      );
    return creatingData;
  };

  const createObjectBanner = (banner) => {
    const backgroundImageURL = banner.background
      ? banner.background
      : banner.backgroundimage;
    const bannerImageURL = banner.banner
      ? banner.banner
      : banner.backgroundimage;
    const posterImageURL = banner.poster
      ? banner.poster
      : banner.backgroundimage;

    return {
      id: banner.id,
      title: banner.title,
      subtitle: banner.subtitle,
      description: banner.description,
      category: banner.category?.code,
      subcategory: banner.subcategory,
      publishDate: banner.publish_date,
      tags: banner.tags?.array_hd7ov6$_0,
      background: {
        id: banner.background,
        url: backgroundImageURL,
        type: {
          name$: "BACKGROUND",
          ordinal$: 2,
        },
      },
      banner: {
        id: banner.banner,
        url: bannerImageURL,
        type: {
          name$: "BANNER",
          ordinal$: 1,
        },
      },
      poster: {
        id: banner.poster,
        url: posterImageURL,
        type: {
          name$: "POSTER",
          ordinal$: 0,
        },
      },
      type: {
        name$: "CONTAINER",
        ordinal$: 5,
      },
      progress: 0,
      canPlay: false,
      canFav: true,
      isFav: false,
      score: 0,
      startTimeMs: null,
      image: {
        url: bannerImageURL,
      },
    };
  };

  const selectedVideo = () => {
    console.log("selectedVideo");
  };

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  const getBannerType = (sectionData, widgetSection) => {
    let bannerType = WIDGET_TYPE_GRID;
    if (
      sectionData.length > 1 ||
      TYPE_BANNER_CONVERSION[widgetSection?.type] ===
        WIDGET_TYPE_GROUP_WIDGET ||
      TYPE_BANNER_CONVERSION[widgetSection?.type] === WIDGET_TYPE_GRID_GROUP
    ) {
      bannerType = !!TYPE_BANNER_CONVERSION[widgetSection?.type]
        ? TYPE_BANNER_CONVERSION[widgetSection?.type]
        : TYPE_BANNER_CONVERSION.DEFAULT_TYPE;
    }
    return bannerType;
  };

  const divSeparateSection = (widgetSection, index) => {
    return index > 0 && !!widgetSection?.length ? (
      !!widgetSection.type &&
        !widgetTypesWithoutSeparateSpace.includes(widgetSection.type) && (
          <div className="FrameworkHomeBackgroundContainer">
            <div className="FrameworkHomeBackgroundV2"></div>
          </div>
        )
    ) : (
      <></>
    );
  };

  const defaultTitleColor = document
    .getElementById("root")
    .style.getPropertyValue("--rowTitleColor");

  const getURLType = (typeBanner) => {
    return typeBanner === WIDGET_TYPE_GRID_GROUP
      ? WIDGET_ACTION_FULL_LIST
      : WIDGET_ACTION_DETAIL;
  };

  return (
    <>
      <div className="Home" key={sectionSelected}>
        {sectionData?.map((widgetSection, index) => {
          let titleColor = widgetSection.titleColor || defaultTitleColor;
          const typeBanner = getBannerType(sectionData, widgetSection);
          const typeURL = getURLType(typeBanner);
          if (!!widgetSection?.lista.length) {
            return (
              <div
                key={widgetSection?.titulo}
                className={"containerWidget"}
                about={widgetSection?.type}
              >
                {divSeparateSection(widgetSection, index, sectionData)}
                <Playlist
                  className="FrameworkHome"
                  clickCallback={selectedVideo}
                  id={widgetSection?.id}
                  hasMoreContents={false}
                  playlist={widgetSection?.lista}
                  hideSubtitle={true}
                  titulo={getWidgetTitle(widgetSection)}
                  turnOffLoading={turnOffLoading}
                  turnOnLoading={turnOnLoading}
                  typeBanner={typeBanner}
                  access={typeURL}
                  rotateTime={null}
                  titleColor={titleColor}
                  pageLocation={"HOME"}
                  origin={ORIGIN_SECTIONS}
                />
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default WithAuthorization(SectionComponent);
