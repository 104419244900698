import React from "react";
import "./ReportContent.css";
import { useTranslation } from "react-i18next";
import HandleClickOutside from "../../../../app/src/Components/DOM/Click/HandleClickOutside";

const ReportContentModalComponent = ({
  refOptions,
  availableReportReasons,
  reasonDescription,
  handleSetReasonDescription,
  hideOptions,
  reason,
  handleSetReason,
  handleReportContent,
  handleCloseModal,
  sentMessage,
}) => {
  const { t } = useTranslation();

  return (
    <div className={"ModalBackground"}>
      <HandleClickOutside elementref={refOptions} callback={hideOptions} />
      <div className="ReportContentOptions" ref={refOptions}>
        {sentMessage ? (
          <div className="ReportContentSentMessage">
            <div className="SentMessageContainer">
              <p className="ReportSubtitle">
                {t("reportcontent.sent_message1")}
              </p>
              <p className="ReportSubtitle">
                {t("reportcontent.sent_message2")}
              </p>
            </div>
            <button
              onClick={() => handleCloseModal()}
              className="button-focused"
            >
              {t("reportcontent.ok")}
            </button>
          </div>
        ) : (
          <>
            <p className="ReportTitle">{t("reportcontent.reason")}</p>
            <p className="ReportSubtitle">
              {t("reportcontent.reason.subtitle")}
            </p>
            <div className="ReportOptionContainer">
              {availableReportReasons &&
                availableReportReasons?.map((p) => (
                  <div
                    className="ReportOption"
                    key={p.name}
                    onClick={() => handleSetReason(p)}
                  >
                    <label className={"container"}>
                      <input
                        className="ReportRadioButton"
                        type="radio"
                        onClick={() => handleSetReason(p)}
                        checked={reason?.name === p.name}
                      />
                      <span className={"checkmark"} />
                      <p className={"checkmark-label"}>{t(p.name)}</p>
                    </label>
                  </div>
                ))}
            </div>
            <form className="ReportOptionForm">
              <label htmlFor="message" className="ReportOptionTitle">
                {t("reportcontent.description")}
              </label>
              <textarea
                name="  message"
                className="ReportOptionInput"
                value={reasonDescription}
                onChange={handleSetReasonDescription}
                placeholder={t("reportcontent.placeholder")}
              />
              <div className="CheckoutButtonContainer">
                <button
                  onClick={() => handleReportContent()}
                  disabled={!reason}
                  className="ReportOptionButtonSend"
                >
                  {t("reportcontent.send")}
                </button>
                <button
                  onClick={() => hideOptions()}
                  className="ReportOptionButtonCancel"
                >
                  {t("reportcontent.cancel")}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ReportContentModalComponent;
