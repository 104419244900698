import React from "react";
import { useTranslation } from "react-i18next";
import icon360 from "@app/Resources/Images/360.svg";
import iconLive from "@app/Resources/Images/live.svg";
import iconVR from "@app/Resources/Images/VR.svg";
import iconAR from "@app/Resources/Images/AR.svg";
import iconFORKED from "@app/Resources/Images/bifurcado.png";
import iconTiivii from "@app/Resources/Images/tiivii.svg";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {
  VIDEO_TYPE_BASICVIDEO,
  VIDEO_TYPE_LIVE,
  VIDEO_TYPE_LIVE_TIIVII, VIDEO_TYPE_TIIVII_LIVE,
  VIDEO_TYPE_VOD_360,
  VIDEO_TYPE_VOD_AR,
  VIDEO_TYPE_VOD_CONTAINER,
  VIDEO_TYPE_VOD_FORKED,
  VIDEO_TYPE_VOD_VR,
} from "../../../../app/src/Utils/Constants";

const VideoType = ({
  subtitle,
  isLiveWidget = false,
  onAir = false,
  type = undefined,
}) => {
  const { t } = useTranslation();

  const VIDEO_TYPES = {
    [VIDEO_TYPE_VOD_360]: {
      ICON: icon360,
    },
    [VIDEO_TYPE_LIVE]: {
      ICON: iconLive,
    },
    [VIDEO_TYPE_TIIVII_LIVE]: {
      ICON: iconLive,
    },
    [VIDEO_TYPE_LIVE_TIIVII]: {
      ICON: iconTiivii,
    },
    [VIDEO_TYPE_VOD_FORKED]: {
      ICON: iconFORKED,
    },
    [VIDEO_TYPE_VOD_AR]: {
      ICON: iconAR,
    },
    [VIDEO_TYPE_VOD_VR]: {
      ICON: iconVR,
    },
  };

  const getSubtitles = (id) => {
    return onAir ? t(`subtitle.${id}`, id): t("subtitle.SCHEDULED");
  };

  const getClass = () => {
    return onAir ? " " : " VideoScheduledTag";
  };

  return (
    <>
      {(type ?? subtitle?.type?.name) === VIDEO_TYPE_VOD_CONTAINER ? (
          MULTITENANT_CONSTANTS[TENANT].showVideoTags &&  (
          <div className="VideoCategory">
            <div className="Subtag PlaylistItemSubLabel PlaylistItemSubLabelSerial">
              {t(getSubtitles(type ?? subtitle.type.name))}
            </div>
          </div>
        )
      ) : MULTITENANT_CONSTANTS[TENANT].showAllIcons && type !== VIDEO_TYPE_BASICVIDEO ? (
        <div className="VideoType">
          <div className="typeIcon">
            {VIDEO_TYPES[type ?? subtitle?.type?.name]?.ICON && (
              <img
                src={VIDEO_TYPES[type ?? subtitle?.type?.name]?.ICON}
                alt={t(getSubtitles(type ?? subtitle?.type?.name))}
              />
            )}
          </div>
        </div>
      ) : (
        !!isLiveWidget && type.toUpperCase() !== VIDEO_TYPE_BASICVIDEO && (
          <div className={"VideoLiveTag" + getClass()}>
            {t(getSubtitles(type ?? subtitle?.type?.name)).toUpperCase()}
          </div>
        )
      )}
    </>
  );
};

export default VideoType;
