import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";

import HandleClickOutside from "../../../../../app/src/Components/DOM/Click/HandleClickOutside";
import "./AvatarModalComponent.css";
import { getAvailableAvatarsService } from "../../../../../app/src/services/services";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import {URL_BASE_IMAGE} from "../../../../../app/src/services/servicesConstants";

const AvatarModalComponent = ({
  refAvatar,
  handleAvatarModal,
  handleSendAvatar,
  userAvatar,
}) => {
  const { t } = useTranslation();
  const { keycloak, initialized } = useKeycloak();

  const [avatars, setAvatars] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(userAvatar);

  const avatarsCallback = (response) => {
    setAvatars(response.data);
  };

  useEffect(() => {
    if (!!keycloak && initialized) {
      const userToken = keycloak?.token;
      const username = keycloak?.tokenParsed?.preferred_username;

      if (!!username) {
        getAvailableAvatarsService(
          MULTITENANT_CONSTANTS[TENANT].tenantServer,
          userToken,
          avatarsCallback
        );
      }
    }
  }, [keycloak, initialized]);

  const handleSelectAvatar = (a) => {
    const obj = { id: a.id, url: `${URL_BASE_IMAGE}${a?.image?.data?.asset_url}` , imageId: a.image.id };
    setSelectedAvatar(obj);
  };

  const handleUploadAvatar = () => {
    handleSendAvatar(selectedAvatar);
  };

  return (
    <div className="AvatarModalBackground">
      <HandleClickOutside elementref={refAvatar} callback={handleAvatarModal} />
      <div className="AvatarModal" ref={refAvatar}>
        <p className="AvatarTitle">{t("perfil.avatar.title")}</p>
        <div className="AvatarsContainer">
          <Row>
            {avatars?.map((a) => (
              <Col xs="4" md="3" key={a.id}>
                <div className="AvatarCont">
                  <img
                    className={
                      "RegisterAvatarImaxe " +
                      (selectedAvatar?.url === `${URL_BASE_IMAGE}${a?.image?.data?.asset_url}`
                        ? "Selected"
                        : "")
                    }
                    key={a.id}
                    onClick={() => handleSelectAvatar(a)}
                    src={`${URL_BASE_IMAGE}${a?.image?.data?.asset_url}`}
                    alt={a.id}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className="ButtonContainer">
          <button
            onClick={handleAvatarModal}
            className="ReportOptionButton button-focused CancelButton"
          >
            {t("global.button.cancel")}
          </button>
          <button
            onClick={handleUploadAvatar}
            //onClick={() => handleSendAvatar(avatar)}
            className="button-focused CancelButton"
          >
            {t("global.button.accept")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AvatarModalComponent;
