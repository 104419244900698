import React, { useRef } from "react";
import PropTypes from "prop-types";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import iconTVG from "../../../../Resources/Images/icono_TVG.png";
import style from "./style.module.css";
import styleHover from "../PlaylistItem/style.module.css";
import { URL_LOGIN } from "../../../Routes/Location";
import { useIsLogin } from "../../utils/utils";
import { actionTypeContainer } from "../../../../Utils/utils";
import ActionButtons from "../ActionButtons/ActionButtons";
import VideoType from "../../../../../../detail/src/Pages/DetailPage/VideoType";
import {
  WIDGET_ACTION_PLAY,
  WIDGET_TYPE_LIVE_V2,
  WIDGET_TYPE_POSTER,
  WIDGET_TYPE_PROGRESS,
} from "../../../../Utils/Constants";
import { useTranslation } from "react-i18next";

const MediaCover = ({ data, typeBanner, event, trigger }) => {

  const { id, title, video, tags, progress } = data;
  const {t} = useTranslation();
  const isLogin = useIsLogin();
  const linkRef = useRef(null);
  const isTVG = tags.includes("TVG");

  const tenantIsFreeToPlay = useSelector((state) => state.commonState.tenantStyles.isFreeToPlay);

  const goToLink = () => {
    if (linkRef.current) {
      linkRef.current.click();
    }
  };

  const isOnAir = () => {
    if (Number.isNaN(data.start)) return false;
    const timestamp = data.start * 1000; // Convert to milliseconds
    const dateFromTimestamp = new Date(timestamp);
    const currentDate = new Date();
    const isTimestampEarlier = dateFromTimestamp < currentDate;
    return isTimestampEarlier;
  };

  const getImage = () => {
    const banner =  typeBanner === WIDGET_TYPE_POSTER ? (data.poster || data.banner) : (data.banner || data.poster);
    return (
      <Link ref={linkRef} to={getLinkProps()}>
        <img
          className={`${style.playlistItemImage} ${styleHover.playlistItemImage} ${
            typeBanner === WIDGET_TYPE_POSTER ? style.playlistItemImage__vertical : ""
          }`}
          src={banner}
          alt={t('global.image') + title}
        />
        {typeBanner === WIDGET_TYPE_PROGRESS && <ProgressBar now={progress} className={style.barProgress} aria-label={t('global.progressbar') + title} />}
      </Link>
    );
  };

  const getLinkProps = () => {
    const accessResult = actionTypeContainer(trigger)?.ROUTE + "/" + id;
    const pathname = !!tenantIsFreeToPlay && !isLogin && accessResult === WIDGET_ACTION_PLAY ? URL_LOGIN : accessResult;

    if (!video) return { pathname: pathname, state: { id } };

    const url = video?.url;
    const type = video?.type;

    return {
      pathname: pathname,
      state: { url, type, id },
    };
  };

  return (
    <div className={`${style.playlistHeader} ${styleHover.playlistHeader}`}>
      {getImage()}
      {isTVG && <img className={style.iconTVG} src={iconTVG} alt={"iconTVG"} />}
      {actionTypeContainer(trigger)?.SHOW_BUTTONS && (
        <ActionButtons data={data} trigger={trigger} event={event} actionLink={false} goToLink={goToLink} />
      )}
      {typeBanner === WIDGET_TYPE_LIVE_V2 && <VideoType type={data.type} subtitle={data} isLiveWidget onAir={isOnAir()} />}
    </div>
  );
};
export default MediaCover;
MediaCover.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    tags: PropTypes.array,
  }).isRequired,
  typeBanner: PropTypes.string,
  trigger: PropTypes.string.isRequired,
};
