import React from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import "../../Ranking.css";
import {
  TYPE_REWARD_CERT,
  TYPE_REWARD_HOOK,
} from "../../../../../../app/src/Utils/Constants";
import achievementIcon from "@app/Resources/Images/Achievement.png";
import crownIcon from "@app/Resources/Images/Reward_crown.svg";
import DiplomaIconComponent from "../../components/DiplomaIconComponent";

const AchievementComponent = ({
  renderTitle,
  achievements = [],
  rewards = [],
}) => {
  const { t } = useTranslation();

  const routeChange = (path) => {
    const win = window.open(path, "_blank");
    win.focus();
  };

  const contentByRewardType = (reward) => {
    switch (reward.type) {
      case TYPE_REWARD_CERT:
        return (
          <button
            onClick={() => routeChange(reward.value)}
            className="PdfButton button-focused"
          >
            {t("global.button.download")}
          </button>
        );
      case TYPE_REWARD_HOOK:
        return (
          <button
            onClick={() => routeChange(reward.value)}
            className="PdfButton button-focused"
          >
            {t("global.button.watch")}
          </button>
        );
      default:
        return "";
    }
  };

  const orderRewardsAndAchievements = [...achievements, ...rewards].sort(
    function (a, b) {
      const fechaA =
        a.achievement_id?.created_on || a.reward_id?.achievement?.created_on;
      const fechaB =
        b.achievement_id?.created_on || b.reward_id?.achievement?.created_on;
      return new Date(fechaB) - new Date(fechaA);
    }
  );

  return (
    <div className="LogrosContainer">
      <Row>
        <Col xs="12">
          {!renderTitle && (
            <p className="Logros-title">{t("ranking.mis_logros")}</p>
          )}
        </Col>
        <Col xs="12" className={"RewardList"}>
          {orderRewardsAndAchievements &&
            orderRewardsAndAchievements.map((e, index) => {
              const isAchievement = !!e.achievement_id;
              const data = isAchievement ? e.achievement_id : e.reward_id;
              const isRewardCert =
                !isAchievement && data.type === TYPE_REWARD_CERT;

              return (
                <Row
                  className="List-user-logros"
                  key={(isAchievement ? "achievement" : "reward") + index}
                >
                  <Col xs="2">
                    {isAchievement ? (
                      <img
                        className={"Icono"}
                        src={achievementIcon}
                        alt={"Achievement-icon"}
                      />
                    ) : isRewardCert ? (
                      <DiplomaIconComponent />
                    ) : (
                      <img
                        className={"Icono"}
                        src={data.value || crownIcon}
                        alt={"Reward-icon"}
                      />
                    )}
                  </Col>
                  <Col xs="10" md={isAchievement ? "10" : "6"}>
                    <p className="Titulo-logro">{data.name}</p>
                    <p className="Descripcion-logro">{data.description}</p>
                  </Col>
                  {!isAchievement && (
                    <Col xs="12" md="4" className={"RewardButtonContainer"}>
                      {contentByRewardType(e)}
                    </Col>
                  )}
                </Row>
              );
            })}
        </Col>
      </Row>
    </div>
  );
};

export default AchievementComponent;
