import {
  URL_NAME_LIVE,
  URL_NAME_PROFILE,
  URL_NAME_SEARCH,
  URL_PROFILE,
  URL_RANKING,
} from "../../Components/Routes/Location";
import { MULTITENANT_CONSTANTS, TENANT } from "../../Resources/Multitenant/tenantConstants";

export const MENU_ID_LIVE = "live";
export const MENU_SCREEN_ID_HOME = 2;
export const MENU_EXCLUDED_SECTIONS_BY_ID = [URL_NAME_LIVE, URL_NAME_PROFILE, URL_NAME_SEARCH];

export const RANKING_SECTION = {
  section: {
    iconId: "podium",
    name: "Ranking",
    screenId: null,
    id: "ranking",
    type: "RANKING",
  },
  url: URL_RANKING,
};

export const STUDIO_SECTION = (url) => {
  return {
    section: {
      iconId: "color-palette-sharp",
      name: MULTITENANT_CONSTANTS[TENANT]?.studioSectionName,
      screenId: null,
      type: "STUDIO",
    },
    url: url,
  };
};

export const CONFIGURATION_SECTION = {
  section: {
    imgIcon: MULTITENANT_CONSTANTS[TENANT]?.configurationSectionImage,
    iconId: "settings-sharp",
    name: "menu.menu_settings",
    screenId: null,
    type: "PROFILE",
  },
  url: URL_PROFILE,
};

export const LOGOUT_SECTION = {
  section: {
    imgIcon: MULTITENANT_CONSTANTS[TENANT]?.logoutSectionImage,
    iconId: "exit-sharp",
    name: "user.logout",
    screenId: null,
    type: "LOGOUT",
  },
  url: "",
};
