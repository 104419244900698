import React, { useContext, useEffect } from "react";
import "../../../Menu.css";
import { useTranslation } from "react-i18next";
import iconDelete from "@app/Resources/Images/delete.svg";
import { Notifications } from "../../../Context/Notifications";

const NotificationMenuComponent = ({
  handleCloseNotificationMenu,
  notifications,
  handleToggleNotifications,
  renderMobileVersion,
}) => {
  const { t } = useTranslation();

  const { deleteNotification, readNotification, reloadNotifications } = useContext(Notifications);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteNotifications = (id) => {
    deleteNotification(id);
    setTimeout(() => {
      reloadNotifications();
    }, 250);
  };

  const handleReadNotifications = (alert) => {
    if (!!alert.isNew) {
      readNotification(alert.id);
      setTimeout(() => {
        reloadNotifications();
      }, 250);
    }
  };

  return (
    <div className={"MenuLateralResContainer"} onClick={handleCloseNotificationMenu}>
      <div className={"NotificationMenuRes"}>
        <div>
          <p className={"NotificationMenuTitle"}>{t("notifications")}</p>
          {/*}  <div className={"NotificationToggleContainer"}>
                        <p className={"NotificationMenuSubtitle"}>{t('notifications.activate')}</p>
                        <Switch checked={activatedNotifications} onChange={handleToggleNotifications}
                                name="NotificationsToggle" size="small" color={"secondary"}
                                className={"MuiSwitch-colorPrimary"}/>
                    </div> */}
        </div>
        <div className={"AlertContainer"}>
          {notifications &&
            notifications.map((alert) => (
              <div
                className={!!alert.isNew ? "AlertCard NewCard" : "AlertCard"}
                onClick={() => handleReadNotifications(alert)}
              >
                {alert.img && <img src={alert.img} className={"AlertImage"} alt={alert.title} />}
                <div className={"AlertTextContainer"}>
                  <p className={"AlertTitle"}>{alert.title}</p>
                  <p className={"AlertText"}>{alert.description}</p>
                </div>
                <div className={"deleteIconMessage"}>
                  <img
                    className={"Icono-trophy"}
                    src={iconDelete}
                    onClick={() => handleDeleteNotifications(alert.id)}
                    alt={t("notification.delete")}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationMenuComponent;
