export const BANNER_HIGHLIGHTED_WIDTH = 1280;
export const BANNER_HIGHLIGHTED_HEIGHT = 320;
export const BANNER_POSTER_WIDTH = 320;
export const BANNER_POSTER_HEIGHT = 500;
export const BANNER_WIDTH = 320;
export const BANNER_HEIGHT = 180;
export const BANNER_LARGE_WIDTH = 640;
export const BANNER_LARGE_HEIGHT = 360;
export const BANNER_CIRCULAR_WIDTH = 150;
export const BANNER_CIRCULAR_HEIGHT = 150;
export const BANNER_CIRCULAR_POSTER_WIDTH = 320;
export const BANNER_CIRCULAR_POSTER_HEIGHT = 500;
export const BANNER_FILTER_SIZE = 220;
export const BANNER_TEXT_HEIGHT = 160;
export const BANNER_TEXT_WIDTH = 250;

export const ACCESS_PLAY = "PLAY";

export const PLAYLIST_URL_VALUE = "2";

export const LIVE_WIDGET_HEIGHT_RATIO = 1200 / 500;

export const TRANSITION_DURATION = 250;
export const DEFAULT_SLIDE_ROTATION_INTERVAL = 5000;

export const TYPE_BANNER_POSTER = "POSTER";
export const TYPE_BANNER_CIRCULAR = "CIRCULAR";
export const TYPE_BANNER_BANNER = "BANNER";
export const TYPE_BANNER_BANNER_LARGE = "BANNER_LARGE";
export const TYPE_BANNER_PROGRESS = "WIDGET_PROGRESS";
export const TYPE_BANNER_TEXT = "NEWS";
export const TYPE_BANNER_FEATURED = 'FEATURED';
export const TYPE_BANNER_LIVE = 'LIVE';
export const TYPE_BANNER_LIVE_2 = 'LIVE_2';
export const TYPE_BANNER_LIVE_VERTICAL = 'LIVE_VERTICAL';
export const TYPE_BANNER_LIST_SCREEN = 'LIST_SCREEN';
export const TYPE_BANNER_LISTSCREEN = 'LISTSCREEN';
export const TYPE_BANNER_BANNERCLICK = 'BANNERCLICK';
export const TYPE_BANNER_LIVE_FILTERS = 'FILTERS';

export const CONTENT_TYPE_CONTAINER = "CONTAINER";

export const VOD_FORKED = "VOD_FORKED";
