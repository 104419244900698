import React from "react";

import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import { renderImageUrl } from "../../../../../app/src/Utils/utils";
import defaultBanner from "../../../../../app/src/Resources/Images/default_banner.png";

const BannerClickComponent = ({ playlist, title }) => {
  const bannerClickContent = playlist[0];

  return (
    <div>
      <div
        className={MULTITENANT_CONSTANTS[TENANT].classPlaylistHighlightItem}
        key={bannerClickContent.id + "-bannerclick"}
      >
        <div
          className={
            MULTITENANT_CONSTANTS[TENANT].bannerHighlightedHeightClassName +
            " playlistHighlightHover"
          }
          onClick={() =>
            bannerClickContent.apirequest !== null
              ? window.open(bannerClickContent.apirequest, "_blank")
              : undefined
          }
        >
          <img
            src={
              bannerClickContent.backgroundimage
                ?
                    bannerClickContent.backgroundimage

                : defaultBanner
            }
            alt={title}
            className={MULTITENANT_CONSTANTS[TENANT].classHeight}
          />
        </div>
      </div>
    </div>
  );
};

export default BannerClickComponent;
