import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import goalTransition from "@app/Resources/Images/transition_Liquid_Elements.png";
import lootBoxVieira from "@app/Resources/Images/lootbox-vieira-apng.png";
import scroll from "@app/Resources/Images/scroll.svg";
import "./AnimationGetGoalComponent.css";
import { Col, Row } from "react-bootstrap";
import { TYPE_REWARD_BADGE, TYPE_REWARD_CERT, TYPE_REWARD_HOOK } from "../../Utils/Constants";

const AnimationGetGoalComponent = ({
  playingAnimationGetGoal,
  closeAnimationGetGoal,
  achievementsState,
  rewardsState,
}) => {
  const [playingAnimation, setPlayingAnimation] = useState(playingAnimationGetGoal);
  const [displayAnimationContent, setDisplayAnimationContent] = useState(false);
  const [imageAnimationContent, setImageAnimationContent] = useState(null);
  const [contentToShow, setContentToShow] = useState("");
  const { t } = useTranslation();

  const getContent = () => {
    setTimeout(() => {
      // closeWindow();
      setImageAnimationContent(<img className={"scroll"} src={scroll} alt="scroll" />);
    }, 1200);
  };

  const getAnimationContent = () => {
    setTimeout(() => {
      setDisplayAnimationContent(true);
      setImageAnimationContent(<img className={"lootBoxVieira"} src={lootBoxVieira} alt="vieira" />);
      getContent();
    }, 1000);
  };

  useEffect(() => {
    if (!!playingAnimationGetGoal) {
      setPlayingAnimation(playingAnimationGetGoal);
      getAnimationContent();
    }
    setTimeout(() => {
      setPlayingAnimation(false);
    }, 1600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingAnimationGetGoal]);

  const getContentToShow = () => {
    return !!rewardsState && rewardsState?.value ? rewardsMessage() : achievementsMessage();
  };

  useEffect(() => {
    setContentToShow(getContentToShow);
    return () => {
      setContentToShow("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getImage = (url) => {
    return <img className={"scroll"} src={url} alt={"Reward"} />;
  };

  const rewardsMessage = () => {
    return (
      <>
        <Col xs={12}>
          {rewardsState?.type === TYPE_REWARD_BADGE ? getImage(rewardsState?.value) : imageAnimationContent}
        </Col>
        {!!rewardsState?.value &&
          (rewardsState?.type === TYPE_REWARD_CERT || rewardsState?.type === TYPE_REWARD_HOOK) && (
            <Col xs={12} className={"content-button"}>
              <div
                className={"Access button-focused button-download"}
                onClick={window.open(rewardsState?.value, "_blank")}
              >
                {t("perfil.download")}
              </div>
            </Col>
          )}
        <Col xs={12}>
          <h2>{t("perfil.congratulations")}</h2>
        </Col>
        <Col xs={12}>
          <p>{!!rewardsState?.name && t("perfil.getGoal") + " " + rewardsState?.name + "!"}</p>
        </Col>
        {!!rewardsState?.value &&
          (rewardsState?.type === TYPE_REWARD_CERT || rewardsState?.type === TYPE_REWARD_HOOK) && (
            <Col xs={12}>
              <p>{!!rewardsState?.value && t("perfil.clickToDownload")}</p>
            </Col>
          )}
      </>
    );
  };
  const achievementsMessage = () => {
    return (
      <>
        <Col xs={12}>
          <h2>{t("perfil.congratulations.achievement")}</h2>
        </Col>
        <Col xs={12}>
          <p>{!!achievementsState?.name && t("perfil.getGoal.achievement") + " " + achievementsState?.name + "!"}</p>
        </Col>
      </>
    );
  };

  return (
    <div className={"bodyAnimation"}>
      {!!playingAnimation && (
        <div className={"animation"}>
          <img src={goalTransition} alt="animacion" />
        </div>
      )}
      {!!displayAnimationContent && (
        <div className={"content"}>
          <Row>
            {contentToShow}
            <Col xs={12} className={"content-button"}>
              <div className={"Access button-focused button-download"} onClick={closeAnimationGetGoal}>
                {t("perfil.continue")}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AnimationGetGoalComponent;
