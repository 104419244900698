import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import style from "./style.module.css";
import styleHover from "../PlaylistItem/style.module.css";

import { useIsLogin, useTranslate } from "../../utils/utils";
import { URL_LOGIN } from "../../../Routes/Location";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../Resources/Multitenant/tenantConstants";
import { actionTypeContainer } from "../../../../Utils/utils";
import FavButton from "../FavButton/FavButton";
import { useSelector } from "react-redux";
import { WIDGET_ACTION_PLAY } from "../../../../Utils/Constants";

const ActionButtons = ({ data, trigger, event, actionLink = true, goToLink }) => {
  const { id, canFav, isFav, video, controller } = data;
  const videoUrl = video?.url;
  const videoType = video?.type;
  const isLogin = useIsLogin();
  const t = useTranslate();
  const tenantIsFreeToPlay = useSelector((state) => state.commonState.tenantStyles.isFreeToPlay);

  const showActionLink = () => {
    if (actionLink) {
      return (
        <Link
          to={{
            pathname:
              !tenantIsFreeToPlay && !isLogin && trigger === WIDGET_ACTION_PLAY
                ? URL_LOGIN
                : actionTypeContainer(trigger).ROUTE + "/" + id,
            state: {
              url: videoUrl,
              type: videoType,
              id: id,
            },
          }}
          aria-label={t("global.button.go.to.player")}
        >
          {!tenantIsFreeToPlay && !isLogin && trigger === WIDGET_ACTION_PLAY ? (
            <span className={style.linkSpace}>{t("ficha.login")}</span>
          ) : (
            <PlayCircleOutlineIcon id={style.playIcon} />
          )}
        </Link>
      );
    } else {
      return !tenantIsFreeToPlay && !isLogin ? (
        <span className={style.linkSpace}>{t("ficha.login")}</span>
      ) : (
        <PlayCircleOutlineIcon id={style.playIcon} onClick={goToLink} />
      );
    }
  };
  return (
    <section
      className={`
            ${styleHover.playFavContainer__top}
            ${MULTITENANT_CONSTANTS[TENANT].ImageWithText ? style.playFavContainer : style.playFavContainer__top}`}
    >
      {showActionLink()}
      {canFav && (
        <FavButton
          className={style.favIcon}
          controller={controller}
          events={event}
          id={id}
          isFavorite={isFav}
          isPlaylist={true}
        />
      )}
    </section>
  );
};

ActionButtons.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    canFav: PropTypes.bool.isRequired,
    isFav: PropTypes.bool.isRequired,
  }).isRequired,
  trigger: PropTypes.string.isRequired,
};

export default ActionButtons;
