import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "../../../Menu.css";
import { checkMenuActive, getSectionName } from "../../../../../Utils/utils";
import "../../../Menu.css";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../../Resources/Multitenant/tenantConstants";

const LateralMenu = ({
  onContainerClick,
  displayMenu,
  sections,
  changeSection,
  studioSection,
  configurationSection,
  logOutSection,
  logout,
  handleToggleNotificationMenu,
  notifications,
  getIconSection,
  configurationIcon,
  logoutIcon,
  reloadIconFlag,
}) => {
  const { t } = useTranslation();

  const userData = useSelector((state) => state.commonState.userData);
  const sectionSelected = useSelector((state) => state.commonState.sectionSelected);

  useEffect(() => {}, [reloadIconFlag]);

  const handleRedirectToStudio = () => {
    window.open(studioSection.url, "_blank");
  };

  return (
    <div className="MenuLateralResContainer" onClick={onContainerClick}>
      <div className="MenuLateralRes ">
        <div className={"MenuSection"}>
          <div className={"PecharMenu"} onClick={displayMenu}>
            <ion-icon name="close" />
          </div>
        </div>
        <div className={"MenuContainer"}>
          <div>
            {sections?.map((a, index) => (
              <div key={a.section.name + "-" + index}>
                <NavLink
                  to={{
                    pathname: a.url,
                    aboutProps: {
                      dinamic: a.section.screenId,
                    },
                  }}
                  onClick={() => changeSection(a.section)}
                  className={"MenuSectionRes " + a.section.iconId}
                  key={a.section.iconId}
                  isActive={(match, location) =>
                    checkMenuActive(match, location, a, sectionSelected, sections, sections)
                  }
                >
                  {getIconSection(a.section?.iconsvg || a.section.iconId)}
                  <span className="TextRes">{getSectionName(a.section)}</span>
                </NavLink>
              </div>
            ))}
          </div>
          <div>
            {notifications?.length > 0 && (
                  <div onClick={() => handleToggleNotificationMenu()}>
                    <p className={"MenuSectionRes"}>
                      <ion-icon name={"notifications"}/>
                      <span className="TextRes">{t("notifications")}</span>
                      <div className={"NotificationsLateralContainer"}>
                        <p className={"NotificationsLateralNumber"}>{notifications?.length}</p>
                      </div>
                    </p>
                  </div>
              )}
            {studioSection.url && !!userData.username && (
                  <div onClick={handleRedirectToStudio}>
                    <p className={"MenuSectionRes " + studioSection.section.iconId}>
                      <ion-icon name={studioSection.section.iconId}/>
                      <span className="TextRes">{t(studioSection.section.name)}</span>
                    </p>
                  </div>
              )}
            {configurationSection && !!userData.username && (
                  <div onClick={() => changeSection(configurationSection.section)}>
                    <p className={"MenuSectionRes " + configurationSection.section.iconId}>
                      <ion-icon name={configurationSection.section.iconId}/>
                      <span className="TextRes">{t(configurationSection.section.name)}</span>
                    </p>
                  </div>
              )}
            {logOutSection && !!userData.username && (
               <div onClick={logout}>
                <p className={
                        "MenuSectionRes " + logOutSection.section.iconId + " " + MULTITENANT_CONSTANTS[TENANT].menuClassLogout
                    }>
                  <ion-icon name={logOutSection.section.iconId} />
                  <span className="TextRes">{t(logOutSection.section.name)}</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LateralMenu;
