import React, { useState, useEffect, useRef } from "react";
import "../Catalogo.css";
import { Col } from "reactstrap";

const SectionsMenuComponent = ({
  sections,
  defaultSections,
  selectNodeParent,
}) => {
  const [section, setSection] = useState(defaultSections[0]);
  const [displaySectionMenu, setDisplaySectionMenu] = useState(false);
  const menuRef = useRef(null);

  const toggleDisplaySectionMenu = () => {
    setDisplaySectionMenu((prev) => !prev);
  };

  const handleChangeSection = (value) => {
    setSection(value);
    selectNodeParent(value);
    toggleDisplaySectionMenu();
  };

  // Gestión del cerrado del menu al hacer click fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        toggleDisplaySectionMenu();
      }
    };

    if (displaySectionMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [displaySectionMenu]);

  useEffect(() => {
    if (defaultSections[0] !== section) {
      setSection(defaultSections[0]);
    }
  }, [defaultSections]);

  return (
    <Col xs={6} sm={3} lg={2} className="OrderMenuContainer">
      <div className="OrderMenu" ref={menuRef}>
        <div
          className="MenuCatalogueContainer"
          onClick={toggleDisplaySectionMenu}
        >
          <span className="MenuCatalogue sectionsMenuTitle">{section}</span>
          <span className={"IconMenuCatalogue"}>
            <ion-icon name="chevron-down-outline" />
          </span>
        </div>
        {displaySectionMenu && (
          <div className="MenuDesplegableOrder">
            {sections.map((node) => (
              <div
                type="button"
                className="MenuSubsectionOrder"
                key={node.name}
                onClick={() => handleChangeSection(node.name)}
              >
                <span className="button-text">{node.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </Col>
  );
};

export default SectionsMenuComponent;
