import React from "react";
import CarouselAuto from "react-bootstrap/Carousel";
import "react-multi-carousel/lib/styles.css";
import "@detail/Pages/DetailPage/CarrouselStyle.css";

import Slide from "./slide/Slide";
import { MULTITENANT_CONSTANTS, TENANT } from "../../Resources/Multitenant/tenantConstants";
import style from "./style.module.css";
import "./carousel.css";
import defaultBanner from "@app/Resources/Images/default_banner.png";

const WidgetHighlight_v2 = (props) => {
  const { playlist, setRef, getRef, trigger, clickCallback, rotateTime } = props;

  return (
    <div className={`${style.carouselContainer} ${style.principal}`}>
      {playlist?.length && (
        <CarouselAuto
          draggable
          id="hightligthCarousel_v2"
          controls={true}
          interval={rotateTime}
          indicators={MULTITENANT_CONSTANTS[TENANT].showSlideHighlightIndicators}
        >
          {playlist?.map((w) => (
            <CarouselAuto.Item key={w.id}>
              <Slide
                w={w}
                setRef={setRef}
                getRef={getRef}
                defaultBanner={defaultBanner}
                trigger={trigger}
                clickCallback={clickCallback}
              />
            </CarouselAuto.Item>
          ))}
        </CarouselAuto>
      )}
    </div>
  );
};

export default WidgetHighlight_v2;
