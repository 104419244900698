import React from "react";
import { Col } from "reactstrap";
import { useTranslation } from "react-i18next";

const UserInfoComponent = ({
  userProfile,
  editUserInfo,
  firstName,
  handleSetFirstName,
  lastName,
  handleSetLastName,
  handleSendUserData,
}) => {
  const { t } = useTranslation();

  return (
    <Col md={12} lg={6} className={"UserDataContainer"}>
      {userProfile?.email && (
        <>
          {editUserInfo ? (
            <label className="campoForm campoFirstName">
              <h2 className={"Title-bold"}>{t("perfil.firstName")}</h2>
              <input
                className="UserInfoInput"
                type="text"
                placeholder={firstName}
                value={firstName}
                onChange={handleSetFirstName}
              />
            </label>
          ) : (
            <div>
              <h2 className={"Title-bold"}>{t("perfil.email")}</h2>
              <p className={"UserInfoP"}>{userProfile.email}</p>
            </div>
          )}
        </>
      )}
      {userProfile?.firstName && userProfile?.lastName && (
        <>
          {editUserInfo ? (
            <label className="campoForm campoFirstName">
              <h2 className={"Title-bold"}>{t("perfil.lastName")}</h2>
              <input
                className="UserInfoInput"
                type="text"
                placeholder={lastName}
                value={lastName}
                onChange={handleSetLastName}
              />
            </label>
          ) : (
            <div>
              <h2 className={"Title-bold"}>{t("perfil.firstlastname")}</h2>
              <p className={"UserInfoP"}>
                {userProfile.firstName} {userProfile.lastName}
              </p>
            </div>
          )}
        </>
      )}
      {editUserInfo && (
        <div
          onClick={handleSendUserData}
          className={
            firstName === userProfile.firstName &&
            lastName === userProfile.lastName
              ? "DisabledEditionButton"
              : "EditionButton"
          }
        >
          <p>{t("perfil.edit.validate")}</p>
        </div>
      )}
    </Col>
  );
};

export default UserInfoComponent;
