import React, { useEffect, useState } from "react";
import { Notifications } from "./Notifications";
import { deleteNotificationService, getNotificationsService, readNotificationService } from "../../../services/services";
import { useKeycloak } from "@react-keycloak/web";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../Resources/Multitenant/tenantConstants";

export function NotificationsProvider(props) {
  const [notificationsData, setNotificationsData] = useState(null);

  const { keycloak } = useKeycloak();
  const username = keycloak?.tokenParsed?.preferred_username;
  const userToken = keycloak?.token;

  const reloadNotifications = () => {
    getNotificationsServiceWrapper();
  };

  const getNotificationsCallback = (data) => {
    const updatedNotifications = data.notifications
      .filter(notification => notification.status !== "deleted")
      .map(notification =>
        notification.read_on === null
          ? { ...notification, isNew: true }
          : { ...notification }
      )
      .reverse();

    setNotificationsData(updatedNotifications);
  };

  const getNotificationsServiceWrapper = () => {
    !!username &&
    getNotificationsService(MULTITENANT_CONSTANTS[TENANT].tenantServer, username, userToken, getNotificationsCallback)
  }

  useEffect(() => {
    getNotificationsServiceWrapper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteNotification = (id) => {
    deleteNotificationService(MULTITENANT_CONSTANTS[TENANT].tenantServer, id, userToken, getNotificationsServiceWrapper)
  };

  const readNotification = (id) => {
    readNotificationService(MULTITENANT_CONSTANTS[TENANT].tenantServer, id, userToken, getNotificationsServiceWrapper)
  };

  const { children } = props;

  return (
    <Notifications.Provider
      value={{
        notifications: notificationsData,
        reloadNotifications: reloadNotifications,
        deleteNotification: deleteNotification,
        readNotification: readNotification,
      }}
    >
      {children}
    </Notifications.Provider>
  );
}
