export const PIXEL_UNIT = "px";

export const METADATA_CONTENT_REPORTED = "ContentReported";
export const METADATA_GAME_ACTION_SENT = "GameActionSent";

export const DETAIL_TYPE_CONTAINER = "CONTAINER";

export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";
export const REDIRECT_TO_HOME = "REDIRECT_TO_HOME";
export const LOAD_NEXT_CONTENT = "LOAD_NEXT_CONTENT";
export const LOAD_CONTENT_PLAYER = "LOAD_CONTENT_PLAYER";
export const REDIRECT_TO_PRODUCTS = "REDIRECT_TO_PRODUCTS";
export const SHOW_QR_MODAL = "SHOW_QR_MODAL";
export const REDIRECT_TO_STRIPE = "REDIRECT_TO_STRIPE";
