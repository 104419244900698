import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ROL_KID, ROL_TEEN, ROL_ADULT } from "./PantallasRexistro";
import { Row, Col } from "reactstrap";
import AlertNotification from "@components/GUI/Alert/Alert";
import RegisterNavigationComponent from "../common/components/RegisterNavigationComponent";

function Avatar(props) {
  const [avatar, setAvatar] = useState("");
  const [invalido, setInvalido] = useState(false);
  const { t } = useTranslation();
  const { updateAvatar } = props;

  //Obter cliente por defecto
  useEffect(() => {
    if (props.datos?.avatar) {
      setAvatar(props.datos.avatar);
    }
  }, [props.datos]);

  const validar = () => {
    if (avatar) {
      props.seguintePantalla();
    } else {
      setInvalido(true);
    }
  };

  const escollerAvatar = (a) => {
    props.ctrlSetAvatar(a);
  };

  return (
    <div className="Avatar">
      <div className="WizardContainer">
        <div className="Descricion">
          {props.datos?.rol === ROL_KID || props.datos?.rol === ROL_TEEN ? (
            <>
              {t("register.textoavatar")}
              <div className={"subTitle"}>{t("register.eligeavatar")}</div>
            </>
          ) : (
            <p>{t("register.textoavatarteen")}</p>
          )}
        </div>
        {invalido && (
          <AlertNotification
            type="error"
            showtitle={false}
            text={t("register.invalidavatar")}
          ></AlertNotification>
        )}
        <div className="AvatarsContainer">
          <Row>
            {props.avatares &&
              props.avatares.map((a, index) => (
                <Col xs="4" md="3" key={a.name + "_" + index}>
                  <div className="AvatarCont">
                    <img
                      className={
                        "RegisterAvatarImaxe " +
                        (avatar && avatar === a.id ? "Selected" : "")
                      }
                      key={a.id}
                      onClick={() => escollerAvatar(a)}
                      src={a.url}
                      alt={a.id}
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </div>
      {!updateAvatar && (
        <RegisterNavigationComponent
          handlerBeforePage={props.anteriorPantalla}
          handlerNextPage={validar}
        />
      )}
    </div>
  );
}

export default Avatar;
