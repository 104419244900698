import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { URL_LOGIN } from "@components/Routes/Location";
import AlertNotification from "@components/GUI/Alert/Alert";
import Iframe from "react-iframe";
import "./Player.css";
import "@videojs/themes/dist/city/index.css";
import "@videojs/themes/dist/fantasy/index.css";
import "@videojs/themes/dist/forest/index.css";
import "@videojs/themes/dist/sea/index.css";
import QRModalComponent from "../../../../app/src/Components/common/QRModalComponent";
import {
  saveContentHashActionCreator,
  saveContentTypeActionCreator,
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import { PLAYER_TYPE_AR } from "./PlayerConstants";
import ErrorMessageModalComponent from "../../../../app/src/Components/common/ErrorMessageModalComponent";
import { getContentPermissions } from "../../../../app/src/services/services";
import {
  TENANT,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import { useKeycloak } from "@react-keycloak/web";
import {
  URL_BASE,
} from "../../../../app/src/services/servicesConstants";

function Player({ id }) {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const userToken = keycloak?.token;
  const baseUrl = URL_BASE;

  const contentHash = useSelector((state) => state.commonState.contentHash);
  const contentType = useSelector((state) => state.commonState.contentType);

  const [player, setPlayer] = useState(null);
  const [error, setError] = useState(false);
  const [errorUserSessionExpired, setErrorUserSessionExpired] = useState(false);
  const [errorVideoUrlUnavailable, setErrorVideoUrlUnavailable] =
    useState(false);
  const [displayQRModal, setDisplayQRModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [hash, setHash] = useState(null);

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  const contentPermissionsCallback = (response) => {
    setHash(response.hash);
  };

  useEffect(() => {
    if (!contentHash) {
      getContentPermissions(
        TENANT,
        id,
        userToken || undefined,
        contentType,
        contentPermissionsCallback
      );
    }

    return () => {
      dispatch(saveContentHashActionCreator(null));
      dispatch(saveContentTypeActionCreator(null));
    };
  }, []);

  useEffect(() => {
    const formattedModel = {};
    turnOnLoading();
    const availableHash = !!hash ? hash : contentHash;
    let videoUrl = `${baseUrl}player/${TENANT}/${id}?`;
    if (!!userToken) {
      videoUrl = `${videoUrl}token=${userToken}&`;
    }

    if (!!availableHash) {
      videoUrl = `${videoUrl}hash=${availableHash}`;
    }

    formattedModel.videoUrl = videoUrl;
    formattedModel.contentType = contentType;

    setPlayer(formattedModel);
  }, [hash, userToken, contentHash]);

  useEffect(() => {
    if (!!player?.videoUrl) {
      turnOffLoading();
    }
  }, [player]);

  const handleCloseQRModal = () => {
    setDisplayQRModal((displayQRModal) => !displayQRModal);
  };

  useEffect(() => {
    if (player?.type === PLAYER_TYPE_AR && !displayQRModal) {
      setDisplayQRModal(true);
    }
  }, [player]);

  return (
    <>
      {
        <div className={"Player"}>
          {player?.videoUrl && (
            <Iframe
              url={player.videoUrl}
              className={"PlayerIframe"}
              width="100%"
              height="100%"
              allow="autoplay; encrypted-media; fullscreen"
              id="player"
              display="initial"
              position="relative"
            />
          )}
        </div>
      }
      {messageModal && <ErrorMessageModalComponent />}
      {displayQRModal && player?.shareUrl && (
        <QRModalComponent
          url={player.shareUrl}
          goBack={true}
          handleCloseQRModal={handleCloseQRModal}
        />
      )}
      {errorUserSessionExpired && <Redirect to={URL_LOGIN} />}
      {error || (errorVideoUrlUnavailable && <AlertNotification />)}
    </>
  );
}

export default Player;
