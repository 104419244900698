export const IMAGE_WIDTH = 320;
export const IMAGE_HEIGHT = 180;

export const TEXT_NAME_BEGINNING = 'catalogue.'
export const DEFAULT_CONTENT_LIMIT = 20

export const DEFAULT_PAGE = 1

export const additionalCatalogueSortingOptions = {
    lowestScore: {
        id: "lowest_score",
        param: "valoration",
        label: "Lowest Score"
    },
    byEpisode: {
        id: "by_episode",
        param: "episode",
        label: "By Episode"
    },
    oldestFirst: {
        id: "oldest_first",
        param: "created_on",
        label: "Oldest First"
    },
    nameAlphabetical: {
        id: "name_alphabetical",
        param: "name",
        label: "Name Alphabetical"
    },
    nameAlphabeticalReversed: {
        id: "name_alphabetical_reversed",
        param: "-name",
        label: "Name Alphabetical Reversed"
    },
    leastPopular: {
        id: "least_popular",
        param: "votes",
        label: "Least Popular"
    },
}

export const catalogueSortingOptions = {
    mostRecentFirst: {
        id: "most_recent_first",
        param: "-created_on",
        label: "Most Recent First"
    },
    mostPopular: {
        id: "most_popular",
        param: "-votes",
        label: "Most Popular"
    },
    highestScore: {
        id: "highest_score",
        param: "-valoration",
        label: "Highest Score"
    },
    titleAlphabetical: {
        id: "title_alphabetical",
        param: "title",
        label: "Title Alphabetical"
    },
    titleAlphabeticalReversed: {
        id: "title_alphabetical_reversed",
        param: "-title",
        label: "Title Alphabetical Reversed"
    }
}