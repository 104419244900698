import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { WIDGET_TYPE_LIVE_V2 } from "../../../Utils/Constants";

export const useTranslate = () => {
  const { t } = useTranslation();
  return t;
};

export const useIsLogin = () => {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated;
};

export const useFormattedDateTime = (startTimeMs = 0, typeBanner) => {
  if (!startTimeMs || Number.isNaN(startTimeMs)) {
    return startTimeMs;
  }

  const value = typeof startTimeMs === "string" ? startTimeMs?.toNumber() : startTimeMs;
  const startDate = new Date(value).toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });

  const startTime = new Date(value).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

  return typeBanner === WIDGET_TYPE_LIVE_V2 ? `${startDate} ${startTime}` : startDate;
};
