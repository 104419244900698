import Keycloak from "keycloak-js";
import {KC_BASE_URI, KC_CLIENT_ID, KC_REALM} from "../Resources/Multitenant/tenantConstants";


const keycloakConfig = {
  realm: KC_REALM,
  url: KC_BASE_URI,
  clientId: KC_CLIENT_ID,
  adapter: 'cordova-native',
};

const keycloak = new Keycloak(keycloakConfig);

export const hasRole = (role) => (!!keycloak ? keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role) : false);

export const hasRoles = (roles) =>
  !!keycloak ? roles.some((r) => keycloak.hasRealmRole(r) || keycloak.hasResourceRole(r)) : false;
export default keycloak;
