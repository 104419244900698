import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { URL_CONTENT } from "@components/Routes/Location";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import style from "./style.module.css";
import { useTranslate } from "../../utils/utils";

function ButtonShowMore({ id }) {
  const url = useMemo(() => `${URL_CONTENT}/widget/${id}`, [id]);
  const t = useTranslate();
  return (
    <Link to={url} className="Titulo button-link-secondary">
      <div className={style.showMoreText}>{t("ficha.showmore")}</div>
      <FontAwesomeIcon icon={faChevronRight} />
    </Link>
  );
}

ButtonShowMore.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ButtonShowMore;
