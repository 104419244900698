export const WIDGET_TYPE_HIGHLIGHT = "Destacado";

export const WIDGET_NAME_FEATURED = "Featured";
export const WIDGET_NAME_SCREEN_WIDGET = "ScreenWidget";
export const WIDGET_NAME_GROUP_WIDGET = "GroupWidget";
export const WIDGET_TYPE_LIVE = "Live";
export const WIDGET_TYPE_LIVE_VERTICAL = "LiveVertical";

export const ACTION_GAME = "GameActionSent";

export const STATE_CONTENT_LOADING = 'LOADING';
export const STATE_CONTENT_LOADED = 'LOADED';
export const STATE_CONTENT_RESET = 'RESET';

export const USER_ANONYMOUS = "anonymous";
