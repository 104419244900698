import React from "react";
import Carousel from "react-multi-carousel";
import FrameworkTitle from "./components/FrameworkTitle/FrameworkTitle";
import PlaylistItem from "./components/PlaylistItem/PlaylistItem";
import "@detail/Pages/DetailPage/CarrouselStyle.css";
import "react-multi-carousel/lib/styles.css";
import style from "./style.module.css";
import { onlyOne, poster_v2, responsive_v2 } from "../../../../detail/src/Pages/DetailPage/CarrouselStyle";
import "./carousel.css";
import {
  TRANSITION_DURATION,
  WIDGET_TYPE_GRID,
  WIDGET_TYPE_GRID_GROUP,
  WIDGET_TYPE_POSTER,
} from "../../Utils/Constants";

const DefaultWidgetComponent_V2 = ({
  id,
  backgroundColor,
  backgroundImage,
  titleColor,
  title,
  hasMoreContents,
  playlist,
  typeBanner,
  trigger,
  event,
}) => {
  return (
    <>
      <div className={style.playlist} style={style}>
        <FrameworkTitle
          id={id}
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          hasMoreContents={hasMoreContents}
          titleColor={titleColor}
          title={title}
        />
        {!!playlist?.length &&
          (typeBanner === WIDGET_TYPE_GRID || typeBanner === WIDGET_TYPE_GRID_GROUP ? (
            <div className={style.gridWidget}>
              {playlist?.map((item) => (
                <PlaylistItem key={item.id} item={item} trigger={trigger} event={event} typeBanner={typeBanner} />
              ))}
            </div>
          ) : (
            <div id="carousel_v2">
              <Carousel
                showDots={false}
                arrows={true}
                responsive={
                  typeBanner === WIDGET_TYPE_POSTER ? poster_v2 : playlist.length === 1 ? onlyOne : responsive_v2
                }
                autoPlay={false}
                transitionDuration={parseInt(TRANSITION_DURATION)}
                customTransition="transform 500ms ease-in-out 0s"
                containerClass={style.carousel}
              >
                {playlist?.map((item) => (
                  <PlaylistItem key={item.id} item={item} trigger={trigger} event={event} typeBanner={typeBanner} />
                ))}
              </Carousel>
            </div>
          ))}
      </div>
    </>
  );
};

export default DefaultWidgetComponent_V2;
