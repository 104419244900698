import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import "bootstrap/dist/css/bootstrap.css";
import iconTrophy from "../../../../Resources/Images/Icon-trophy.svg";
import "./BarraSuperior.css";
import PointsAnimation from "./components/PointsAnimation";
import MenuLiveComponent from "./components/MenuLiveComponent";
import { SHOW_LENGUAJE, SHOW_SEARCH, SWITCHER_WIDTH } from "../../../../Utils/Constants";
import { LanguagesComponent } from "./components/LanguagesComponent";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../Resources/Multitenant/tenantConstants";
import { URL_HOME, URL_SEARCH, URL_PROFILE } from "../../../../Components/Routes/Location";
import { getFileService } from "../../../../services/services";
import SearchBarMobile from "./components/SearchBarMobile";
import { saveUserURLAvatarActionCreator } from "../../../../actions/commonActions";
import {URL_BASE_IMAGE} from "../../../../services/servicesConstants";

const BarraSuperior = ({ className, handleChangeSection }) => {
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.commonState.userData);

  const [renderMobileVersion, setRenderMobileVersion] = useState(window.innerWidth <= SWITCHER_WIDTH);
  const [showLanguage, setShowLanguage] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [userURLAvatar, setUserURLAvatar] = useState("");

  useEffect(() => {
    if (window.location.pathname === URL_SEARCH) {
      setShowSearch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelShow = (value) => {
    if (value === SHOW_LENGUAJE) {
      setShowLanguage(!showLanguage);
      setShowSearch(false);
    } else {
      if (value === SHOW_SEARCH) {
        setShowSearch(!showSearch);
        setShowLanguage(false);
      }
    }
  };

  const handleGoToHome = () => {
    if (window.location.pathname !== URL_HOME) {
      history.push(URL_HOME);
    }
  };

  const handleGoToLogin = () => {
    /*history.push(URL_LOGIN);*/
    keycloak.login({ locale: MULTITENANT_CONSTANTS[TENANT]?.defaultLocale });
  };

  const handleResize = () => {
    setRenderMobileVersion(window.innerWidth <= SWITCHER_WIDTH);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  const getFileCallback = (response) => {
    setUserURLAvatar(`${URL_BASE_IMAGE}${response.data.data.asset_url}`);
    dispatch(saveUserURLAvatarActionCreator(response.data.data.url));
  };

  useEffect(() => {
    if (!!userData?.avatar) {
      const userToken = keycloak?.token;
      getFileService(MULTITENANT_CONSTANTS[TENANT].tenantServer, userData.avatar, userToken, getFileCallback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div className={`BarraSuperior ${className ?? ""}`}>
      <div onClick={handleGoToHome} className={"LogoContainer"}>
        <div className="Logo-Barra" />
      </div>
      <div className={"groupOptions"}>
        <div className="SearchBarWrapper">
          <div className="SearchBarContainer">
            <SearchBarMobile show={showSearch} handelShow={handelShow} />
          </div>
        </div>
        {userURLAvatar && (
          <div className="Usuario">
            <div className={"UserSeccion"}>
              <Link to={URL_PROFILE}>
                <img className="AvatarMenu" src={userURLAvatar} alt="Usuario" />
                {MULTITENANT_CONSTANTS[TENANT].gamification && userData?.points && (
                  <div className="RankingMenu">
                    <img className="TrophyMenu" src={iconTrophy} alt="IconTropy" />
                    <PointsAnimation userPoints={userData.points} />
                  </div>
                )}
              </Link>
            </div>
          </div>
        )}
        {!keycloak?.authenticated &&
          (!renderMobileVersion || (renderMobileVersion && MULTITENANT_CONSTANTS[TENANT]?.showLoginButtonInMobile)) && (
            <div className="Usuario">
              {renderMobileVersion ? (
                <div onClick={handleGoToLogin} className={"LoginButton"}>
                  <ion-icon name="log-in-outline" />
                </div>
              ) : (
                <div onClick={handleGoToLogin} className={"LoginButton"}>
                  {t("user.login")}
                </div>
              )}
            </div>
          )}
        <LanguagesComponent show={showLanguage} handelShow={handelShow} renderMobileVersion={renderMobileVersion} />
        <OverlayTrigger
          delay={{ hide: 450, show: 300 }}
          placement="bottom"
          overlay={(props) => <Tooltip {...props}>{t("subtitle.LIVE")}</Tooltip>}
        >
          <MenuLiveComponent
            userAge={userData.role?.maxAge}
            rol={userData.role}
            theme={keycloak.clientId}
            handleChangeSection={handleChangeSection}
          />
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default BarraSuperior;
