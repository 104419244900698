import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import { ROL_KID, ROL_TEEN } from "./PantallasRexistro";
import ReactDice from "react-dice-complete";
import "react-dice-complete/dist/react-dice-complete.css";
import AlertNotification from "@components/GUI/Alert/Alert";
import RegisterNavigationComponent from "../common/components/RegisterNavigationComponent";
import { generateUsernameService } from "../../../../app/src/services/services";
import { TENANT } from "../../../../app/src/Resources/Multitenant/tenantConstants";

export const invalidCharacter = "Ñ";

function NameUser(props) {
  const { ctrlSetUsername, invalidUsername, setDatosState } = props;
  const [nomeRandom, setNomeRandom] = useState(null);
  const [nome, setNome] = useState("");
  const [invalido, setInvalido] = useState(false);

  const { t } = useTranslation();
  let reactDice;

  const generateUsernameCallback = (response) => {
    const randomUsername = response.username;

    setDatosState((prev) => ({ ...prev, username: randomUsername }));
    setNomeRandom(randomUsername);
  };

  const xerarNovoNome = () => {
    generateUsernameService(TENANT, generateUsernameCallback);
    setInvalido(false);
  };

  //Obter cliente por defecto
  useEffect(() => {
    if (!props.datos.username) {
      xerarNovoNome();
    } else {
      setNomeRandom(props.datos.username);
    }

    if (props.datos.username) {
      setNome(props.datos.username);
    }
  }, []);

  const [textToDispache] = useDebounce(nome, 300);

  useEffect(() => {
    ctrlSetUsername(textToDispache);
  }, [textToDispache]);

  const handleChangeNome = (e) => {
    const userName = e.target.value;
    const withSpace = /\s/;
    if (
      userName.toUpperCase().includes(invalidCharacter) ||
      withSpace.test(userName)
    ) {
      setInvalido(true);
    } else {
      setInvalido(false);
    }
    setNome(userName);
  };

  const validar = () => {
    const data = props.datos;

    if (data.rol === ROL_KID) {
      if (nomeRandom) {
        ctrlSetUsername(nomeRandom);
        data.username = nomeRandom;
        props.seguintePantalla();
      }
    }

    if (data.rol === ROL_TEEN) {
      if (nome && nome.length > 0) {
        setInvalido(false);
        ctrlSetUsername(nome);
        data.username = nome;
        props.seguintePantalla();
      } else {
        setInvalido(true);
      }
    }
  };

  const rollAll = () => {
    reactDice.rollAll();
  };

  const dados = () => {
    rollAll();
    xerarNovoNome();
  };

  return (
    <div className="NameUser">
      <div className="WizardContainer">
        <div className="Descricion">
          {props.datos.rol === ROL_KID && (
            <>
              {t("register.titlenombrekid")}
              <div className={"subtitle"}>{t("register.textonombrekid")}</div>
            </>
          )}
          {props.datos.rol === ROL_TEEN && (
            <p>{t("register.textonombrepreteen")}</p>
          )}
        </div>
        {props.datos.rol === ROL_KID && (
          <>
            <div className="NomeRandom">{nomeRandom}</div>
            <div className="BotonRandom">
              <div onClick={dados}>
                <ReactDice
                  numDice={2}
                  dotColor={"#ffffff"}
                  faceColor={"#1FAADE"}
                  margin={2}
                  dieSize={80}
                  rollTime={0.2}
                  disableIndividual={true}
                  ref={(dice) => (reactDice = dice)}
                />
              </div>
            </div>
          </>
        )}
        {props.datos.rol === ROL_TEEN && (
          <>
            <label className="campoForm">
              <input
                className="InputNome"
                type="text"
                value={nome}
                onChange={handleChangeNome}
              />
            </label>
          </>
        )}
        {(invalido || (invalidUsername && props.datos.username !== "")) && (
          <AlertNotification
            type="error"
            showtitle={false}
            text={t("register.invalidusername")}
          />
        )}
      </div>
      <RegisterNavigationComponent
        handlerBeforePage={props.anteriorPantalla}
        handlerNextPage={validar}
        followButtonDisabled={invalidUsername || invalido}
      />
    </div>
  );
}

export default NameUser;
