import React from "react";
import { Col } from "reactstrap";
import { useTranslation } from "react-i18next";

const SortMenuComponent = ({
  displayMenu,
  nameOrder,
  showMenu,
  sortingOptions,
  handleClickOrder,
  getTextSort,
}) => {
  const { t } = useTranslation();

  return (
    <Col xs={12} sm={3} lg={2} className="OrderMenuContainer">
      <div id="sortMenu" className="OrderMenu">
        <div className="MenuCatalogueContainer" onClick={displayMenu}>
          <span className="MenuCatalogue orderMenuTitle">{t(nameOrder)}</span>
          <span className={"IconMenuCatalogue"}>
            <ion-icon name="chevron-down-outline" />
          </span>
        </div>
        {showMenu && (
          <div className="MenuDesplegableOrder">
            {Object.entries(sortingOptions).map(([key, option]) => (
              <div
                type="button"
                className="MenuSubsectionOrder"
                key={option.id}
                onClick={() => handleClickOrder(option.id, option.param)}
              >
                <span className="button-text">{getTextSort(option.id)}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </Col>
  );
};

export default SortMenuComponent;
