import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import VideoType from "../../../../../detail/src/Pages/DetailPage/VideoType";
import GalleryCardComponent from "../../../../../app/src/Components/common/GalleryCardComponent";
import ContentCardComponent from "../../ContentCardComponent";
import { IMAGE_HEIGHT, IMAGE_WIDTH } from "../../../../CatalogueConstants";
import defaultBanner from "../../../../../app/src/Resources/Images/default_banner.png";
import { URL_VIDEODETAIL } from "../../../../../app/src/Components/Routes/Location";
import {renderImageBannerData} from "../../../../../app/src/Utils/utils";
import { hasContentChildren, hasNoContentChildren } from "../utils/utils";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import ContentCardComponentV2 from "../../ContentCardComponentV2";

const ContentComponent = ({
  contents,
  handleGoToVideoDetail,
  changeShowNoContent,
  showNoContent,
  category,
}) => {
  const { t } = useTranslation();

  return contents?.map((content) => {
    return (
      <div key={content?.id}  className="ContentContainer">
        {hasContentChildren(content) && (
          <>
            {showNoContent && changeShowNoContent()}
            <div
              className="PlaylistItemContainer VideoContainer"
              onClick={() => handleGoToVideoDetail(content?.id)}
            >
              <GalleryCardComponent
                image={renderImageBannerData(content)}
                width={IMAGE_WIDTH}
                height={IMAGE_HEIGHT}
              />
              <div className="contentFooter">
                <div className="PlaylistItemTitle-Default">{content?.title}</div>
                <VideoType subtitle={content}/>
                <div className="PlayFavContainer">
                  <div className="boton-play">
                    <Link
                        className="BotonVer button-focused"
                        to={{
                          pathname: URL_VIDEODETAIL + "/" + content?.id,
                        }}
                    >
                      {t("ficha.seeDetail")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {hasNoContentChildren(content) && (
          <>
            {showNoContent && changeShowNoContent()}
            {MULTITENANT_CONSTANTS[TENANT].discoverContentsVersion === 2 ? (
              <ContentCardComponentV2
                content={content}
                defaultBanner={defaultBanner}
                handleSelectedMedia={handleGoToVideoDetail}
                imageCard={renderImageBannerData(content)}
                category={category} />
            ) : (
              <ContentCardComponent
                content={content}
                defaultBanner={defaultBanner}
                handleSelectedMedia={handleGoToVideoDetail}
                imageCard={renderImageBannerData(content)} />
            )}
          </>
        )}
      </div>
    );
  });
};

export default ContentComponent;
