import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { evaluateItemClass } from "../utils";
import {
  actionTypeContainer,
  renderImageUrl,
} from "../../../../../../app/src/Utils/utils";
import VideoType from "../../VideoType";

const SportLiveCardComponent = (props) => {
  const {
    overOnCard,
    i,
    w,
    playlist,
    setOverOnCard,
    trigger,
    clickCallback,
    defaultBanner,
  } = props;

  const isOnAir = () => {
    return (
      new Date(w.start * 1000) < new Date() &&
      new Date(w.finish * 1000) > new Date()
    );
  };

  return (
    <div
      className={evaluateItemClass({
        i,
        overOnCard,
        playlist,
        itemClass: "PlaylistItemContainer",
      })}
      key={w.id}
      onMouseEnter={() => setOverOnCard(i)}
      onMouseLeave={() => setOverOnCard(false)}
    >
      <Link
        to={{
          pathname: actionTypeContainer(trigger).ROUTE + "/" + w.id,
        }}
      >
        <div
          className="PlaylistItem Live"
          onClick={clickCallback ? () => clickCallback(w.id) : ""}
          key={w.title}
        >
          <img
            className="PlaylistItemImage"
            src={
              !!w.background || !!w.banner
                ?   !!w.banner ? w.banner : w.background
                : defaultBanner
            }
            alt={w.title}
          />
        </div>
        <div className={"textLive liveSportDesign"}>
          <div className="PlaylistItemTitle">{w.title}</div>
          {!!w?.start && (
            <div className="PlaylistItemSubtitle">
              {new Date(w.start * 1000).toLocaleString()}
            </div>
          )}
        </div>
      </Link>
      <VideoType subtitle={w} isLiveWidget onAir={isOnAir()} type={w.type} />
    </div>
  );
};

export default SportLiveCardComponent;
