export const USER_AVATAR = 'avatar';
export const USER_USER = 'user';
export const USER_CATEGORY = 'category';

export const PASSWORD_ERROR_INVALID_CREDENTIALS = 'InvalidCredentials';
export const PASSWORD_ERROR_INVALID_NEW_PASSWORD = 'InsecureCredentials';
export const PASSWORD_ERROR_SECURE_CREDENTIALS = 'SecureCredentials';
export const PASSWORD_ERROR_FAILED_NEW_PASSWORD = 'CredentialsUpdateFailed';

export const PASSWORD_WARNING_RESET_TIMEOUT = 2000;

export const PASSWORD_VALIDATION_REGEX = /^(?=.*[A-Za-z])(?=.*\\d)(?=.*[!\"#\$%&'()*+,-./:;<=>?@\\[\\]^_`{|}~])[A-Za-z\\d!\"#\$%&'()*+,-./:;<=>?@\\[\\]^_`{|}~]{8,}\$/;
