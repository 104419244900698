import React, { useEffect, useRef, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import Favicon from "react-favicon";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import i18n from "./i18n";
import { MULTITENANT_CONSTANTS, TENANT } from "./Resources/Multitenant/tenantConstants";
import {
  displayOnBoardingActionCreator,
  loadTenantStylesActionCreator,
  saveInterfaceUrlsActionCreator,
  saveSponsorsActionCreator,
  saveTenantLanguageActionCreator,
  saveUserDataActionCreator,
  saveLegalActionCreator,
  saveTenantRulesActionCreator,
  storeSectionsMenuActionCreator,
} from "./actions/commonActions";
import AnimationGetGoalComponent from "./Components/common/AnimationGetGoalComponent";
import {
  ANIMATION_GET_GOAL_ACHIEVEMENTS,
  ANIMATION_GET_GOAL_PLAY,
  ANIMATION_GET_GOAL_REWARDS,
  DEVICES,
  LOCAL_STORAGE_KEY__LOCALE,
  LOCAL_STORAGE_KEY__SKIP_ONBOARDING,
} from "./Utils/Constants";
import OnBoardingComponent from "./Components/common/OnBoarding/OnBoardingComponent";
import ModalAppsComponent from "./Components/common/ModalAppsComponent";
import { URL_LEGAL } from "./Components/Routes/Location";
import { getRewardsService, getTenantRules } from "./services/services";
import { getUserDataService, getMenu } from "./services/services";
import {getTokenFromCookie, setAchievementsAndRewardsInLocalStorage, setHtmlLang} from "./Utils/utils";
import { SearchProvider } from "../../search/src/Context/SearchProvider";
import { MENU_EXCLUDED_SECTIONS_BY_ID } from "./Pages/Menu/MenuConstants";


const App = ({ children, setScrollRef }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const scrollRef = useRef();

  const token = getTokenFromCookie();
  const { keycloak, initialized } = useKeycloak({token});
  const accessToken = keycloak?.token;

  useEffect(() => {
    setScrollRef(scrollRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef]);

  const renderApp =
    useSelector((state) => state.commonState.onBoarding) || !MULTITENANT_CONSTANTS[TENANT]?.showOnBoarding;

  const handleDisplayOnBoarding = (value) => {
    dispatch(displayOnBoardingActionCreator(value));
  };

  const handleSkipOnBoarding = (value) => {
    localStorage.setItem(LOCAL_STORAGE_KEY__SKIP_ONBOARDING, JSON.stringify(value));
    handleDisplayOnBoarding(value);
  };

  const userData = useSelector((state) => state.commonState.userData);
  const tenantStyles = useSelector((state) => state.commonState.tenantStyles);
  const storageLocale = localStorage.getItem(LOCAL_STORAGE_KEY__LOCALE) || MULTITENANT_CONSTANTS[TENANT]?.defaultLocale;

  const loadTenantStyles = (data) => {
    dispatch(loadTenantStylesActionCreator(data));
  };

  const [playingAnimationGetGoal, setPlayingAnimationGetGoal] = useState(false);
  const [achievementsState, setAchievementsState] = useState(null);
  const [rewardsState, setRewardsState] = useState(null);
  const [modalAppsMobile, setModalAppsMobile] = useState(false);
  const [device, setDevice] = useState(null);
  const [tenantLanguages, setTenantLanguages] = useState([]);
  const [currentLegalVersion, setCurrentLegalVersion] = useState(null);
  const [acceptedLegalVersion, setAcceptedLegalVersion] = useState(null);

  const openAnimationGetGoal = () => {
    setTimeout(() => {
      setPlayingAnimationGetGoal(true);
    }, 4000);
  };

  const closeAnimationGetGoal = () => {
    setPlayingAnimationGetGoal(false);
    localStorage.setItem(ANIMATION_GET_GOAL_PLAY, JSON.stringify(0));
    localStorage.setItem(ANIMATION_GET_GOAL_ACHIEVEMENTS, JSON.stringify({}));
    localStorage.setItem(ANIMATION_GET_GOAL_REWARDS, JSON.stringify({}));
    setAchievementsState("");
    setRewardsState("");
  };

  const getUserDataCallback = (response) => {
    setAcceptedLegalVersion(response.acceptedlegalversion);
    dispatch(saveUserDataActionCreator(response));
  };

  useEffect(() => {
    if (acceptedLegalVersion !== null && acceptedLegalVersion !== undefined) {
      if (acceptedLegalVersion !== currentLegalVersion) {
        history.push(URL_LEGAL);
      }
    }
  }, [history, acceptedLegalVersion, currentLegalVersion]);

  const getTranslationValue = (translations, language, field) => {
    const translation = translations?.find((t) => t.language === language);
    if (translation) {
      return translation[field];
    }
    return null;
  };

  const getMenuCallback = (response) => {
    const mainInterface = response;

    const interfaceUrls = {
      termsAndConditionsUrl: getTranslationValue(mainInterface?.translations, storageLocale, "terms_conditions_url"),
      privacy_url: getTranslationValue(mainInterface?.translations, storageLocale, "privacy_url"),
    };

    setTenantLanguages(mainInterface?.languages);
    setCurrentLegalVersion(mainInterface?.legal?.version);

    // TODO: almacenar toda la interface en el store directamente
    dispatch(saveLegalActionCreator(mainInterface.legal));
    dispatch(saveInterfaceUrlsActionCreator(interfaceUrls));
    dispatch(saveSponsorsActionCreator(mainInterface.sponsors));
    dispatch(saveTenantLanguageActionCreator(mainInterface.languages));

    const dataMenu = mainInterface.menu;

    if (!!dataMenu?.length) {
      const newDataMenu = dataMenu.map((menu) => {
        const { icon, name, translations, screen, type, order, iconsvg } = menu;
        return {
          section: {
            iconId: icon,
            iconsvg,
            name,
            translations,
            id: name,
            screenId: screen ?? null,
            type: type ?? name,
            order: order ?? 9999,
          },
        };
      });

      const filteredSections = newDataMenu.filter((s) => {
        return !MENU_EXCLUDED_SECTIONS_BY_ID.includes(s?.section?.id?.toUpperCase());
      });

      dispatch(storeSectionsMenuActionCreator(filteredSections));
    }

    renderCssValues(mainInterface);
  };

  useEffect(() => {
    if (!!keycloak && initialized) {
      const username = keycloak?.tokenParsed?.preferred_username;
      if (!!username) {
        getUserDataService(MULTITENANT_CONSTANTS[TENANT].tenantServer, username, accessToken, (r) =>
          getUserDataCallback(r),
        );
      }
      getMenu(getMenuCallback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, initialized]);

  useEffect(() => {
    if (!!accessToken) {
      getUserRewards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const getFromLocalStorage = (field) => {
    return localStorage.getItem(field);
  };

  useEffect(() => {
    const activeAnimation = getFromLocalStorage(ANIMATION_GET_GOAL_PLAY);

    if (activeAnimation === "1") {
      const rewardsLocal = getFromLocalStorage(ANIMATION_GET_GOAL_REWARDS);
      !!rewardsLocal && setRewardsState(JSON.parse(rewardsLocal));
      const achievementLocal = getFromLocalStorage(ANIMATION_GET_GOAL_ACHIEVEMENTS);
      !!achievementLocal && setAchievementsState(JSON.parse(achievementLocal));
      openAnimationGetGoal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCssValues = (conf) => {
    const root = document.documentElement;
    root.style.setProperty("--textFont", conf.font);
    root.style.setProperty("--backgroundColor", conf.backgroundcolor);
    root.style.setProperty("--backgroundImage", conf.backgroundimage);
    root.style.setProperty("--appColor", conf.appcolor);
    root.style.setProperty("--textColor", conf.textcolor);
    root.style.setProperty("--titleFont", conf.titlefont);
    root.style.setProperty("--titleColor", conf.titlecolor);
    root.style.setProperty("--hoverColor", conf.hovercolor);
    root.style.setProperty("--focusColor", conf.focuscolor);

    root.style.setProperty("--defaultButtonColor", conf.buttondefaultbackgroundcolor);
    root.style.setProperty("--defaultButtonTextColor", conf.buttondefaulttextcolor);
    root.style.setProperty("--defaultButtonBorderColor", conf.buttondefaultbordercolor);

    root.style.setProperty("--hoverButtonColor", conf.buttonhoverbackgroundcolor);
    root.style.setProperty("--hoverButtonBorderColor", conf.buttonhoverbordercolor);
    root.style.setProperty("--hoverButtonTextColor", conf.buttonhovertextcolor);

    root.style.setProperty("--focusedButtonColor", conf.buttonfocusedbackgroundcolor);
    root.style.setProperty("--focusedButtonBorderColor", conf.buttonfocusedbordercolor);
    root.style.setProperty("--focusedButtonTextColor", conf.buttonfocusedtextcolor);

    root.style.setProperty("--footerBackgroundColor", conf.footerbackgroundcolor);

    root.style.setProperty("--headerBackgroundColor", conf.headerbackgroundcolor);
    root.style.setProperty("--headerIconColor", conf.headericoncolor);
    root.style.setProperty("--headerHoverIconColor", conf.headerhovericoncolor);

    root.style.setProperty("--bannerWidgetBorderRadius", conf.bannerborderradius + '%');

    root.style.setProperty("--sidebarIconColor", conf.sidebariconcolor);
    root.style.setProperty("--sidebarHoverIconColor", conf.sidebarhovericoncolor);

    root.style.setProperty("--bannerWidgetTitleColor", conf.widgetbannertitlecolor);
    root.style.setProperty("--bannerWidgetImageColor", conf.widgetbannerimagecolor);
    root.style.setProperty("--bannerWidgetBgColor", conf.widgetbannerbgcolor);

    root.style.setProperty("--discoverDropBgColor", conf.discoverdropbgcolor);

    root.style.setProperty("--logo", `url(${conf.logo})`);
    root.style.setProperty("--tagBackgroundColor", conf.tagbackgroundcolor);
    root.style.setProperty("--tagTextColor", conf.tagtextcolor);
    root.style.setProperty("--featuredWidgetTitleColor", conf.widgetdestacadotitlecolor);
  };

  /*const changeKeycloak = () => {
    //   setKeycloak(k);
  };*/

  /*const appLinksCallback = (res) => {
    //TODO: comprobar que las urls de las apps son válidas
    setAppUrlData(res);
  };*/

  /*useEffect(() => {
    getAppLinks(appLinksCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);*/

  useEffect(() => {
    // Seleccionamos el idioma disponible:
    // 1- El del usuario si tenemos datos
    // 2- El idioma por defecto que nos viene en la petición de la interfaz general "MAIN" si el usuario no tiene datos
    // 3- El que almacenamos localmente por defecto en caso contrario a las condiciones anteriores
    if ((!!keycloak && initialized) || i18n.language) {
      if (!!userData && !!userData.language) {
        i18n.changeLanguage(userData.language);
        localStorage.setItem(LOCAL_STORAGE_KEY__LOCALE, userData.language);
      } else if (!userData && !!tenantLanguages) {
        i18n.changeLanguage(tenantLanguages[0]);
        localStorage.setItem(LOCAL_STORAGE_KEY__LOCALE, tenantLanguages[0]);
      } else {
        if (!storageLocale) {
          i18n.changeLanguage(MULTITENANT_CONSTANTS[TENANT]?.defaultLocale);
          localStorage.setItem(LOCAL_STORAGE_KEY__LOCALE, MULTITENANT_CONSTANTS[TENANT]?.defaultLocale);
        } else {
          i18n.changeLanguage(storageLocale);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, userData]);

  /*const handleSplashOutput = (o) => {
    //TODO: ver cómo gestionar cuando el usuario tiene un nuevo logro/recompensa para mostrar la modal
    if (interops.getKotlinName(o) === "GameActionSent") {
      setAchievementsAndRewardsInLocalStorage(o);
    }
  };*/

  const rewardsCallback = (res) => {
    !!res &&
    setAchievementsAndRewardsInLocalStorage(res);
  };

  const getUserRewards = () => {
    const username = keycloak?.tokenParsed?.preferred_username;

    getRewardsService(TENANT, username, accessToken, rewardsCallback);
  };

  const isTenantFreeToPlay = (response) => {
    return !(!response.areAnonAllowed && response.hasMonetization);
  };

  const callbackTenant = (response) => {
    dispatch(saveTenantRulesActionCreator(response));
    const isFreeToPlay = isTenantFreeToPlay(response);
    loadTenantStyles({ ...MULTITENANT_CONSTANTS[TENANT], isFreeToPlay: isFreeToPlay });
  };

  useEffect(() => {
    const tenants = Object.keys(MULTITENANT_CONSTANTS);

    tenants.find((item) => item === TENANT)
      ? getTenantRules(callbackTenant)
      : loadTenantStyles(MULTITENANT_CONSTANTS.default);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tenantStyles?.pageTitle) {
      document.title = tenantStyles.pageTitle;
      if (tenantStyles?.keywords?.length > 0) {
        document.title = tenantStyles.keywords[0] + " | " + MULTITENANT_CONSTANTS[TENANT].pageTitle;
      } else {
        document.title = MULTITENANT_CONSTANTS[TENANT].pageTitle;
      }
    }
    if (tenantStyles?.description) {
      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.name = "description";
        document.head.appendChild(metaDescription);
      }
      metaDescription.content = tenantStyles.description;
    }
    if (tenantStyles?.keywords) {
      let metaKeywords = document.querySelector('meta[name="keywords"]');
      if (!metaKeywords) {
        metaKeywords = document.createElement('meta');
        metaKeywords.name = "keywords";
        document.head.appendChild(metaKeywords);
      }
      metaKeywords.content = tenantStyles.keywords.join(', ');
    }
    if (tenantStyles?.canonical) {
      let linkCanonical = document.querySelector('link[rel="canonical"]');
      if (!linkCanonical) {
        linkCanonical = document.createElement('link');
        linkCanonical.rel = "canonical";
        document.head.appendChild(linkCanonical);
      }
      linkCanonical.href = tenantStyles.canonical;
    }
    if (tenantStyles?.favicon) {
      let linkFavicon = document.querySelector('link[rel="icon"]');
      if (!linkFavicon) {
        linkFavicon = document.createElement('link');
        linkFavicon.rel = "icon";
        linkFavicon.type = "image/x-icon";
        document.head.appendChild(linkFavicon);
      }
      linkFavicon.href = tenantStyles.favicon;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantStyles]);

  useEffect(() => {
    setHtmlLang(storageLocale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageLocale]);

  const toggleOpenModal = () => {
    setModalAppsMobile(!modalAppsMobile);
  };

  useEffect(() => {
    const platform = window.navigator.platform;

    for (const device of Object.keys(DEVICES)) {
      if (platform.includes(device)) {
        setDevice(DEVICES[device]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!!tenantStyles?.favicon && <Favicon url={tenantStyles?.favicon} />}
      <>
        {renderApp !== false ? (
          <SearchProvider>
            <div ref={scrollRef} />
            {children}
          </SearchProvider>
        ) : (
          <OnBoardingComponent handleSkipOnBoarding={handleSkipOnBoarding} />
        )}
      </>
      {!!playingAnimationGetGoal && (
        <AnimationGetGoalComponent
          playingAnimationGetGoal={playingAnimationGetGoal}
          closeAnimationGetGoal={closeAnimationGetGoal}
          achievementsState={achievementsState}
          rewardsState={rewardsState}
        />
      )}
      {!!modalAppsMobile && !!device && (
        <ModalAppsComponent
          className={"classModalApps"}
          isOpen={true}
          toggleOpenModal={toggleOpenModal}
          content={"modal_apps_content"}
          title={"modal_apps_title"}
          subContent={"modal_apps_sub_content"}
          appUrlData={{}}
          device={device}
        />
      )}
    </>
  );
};

export default App;
