import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const SelectLanguagesComponent = ({ language, languages }) => {
  const { t, i18n } = useTranslation();

  const languagesName = {
    es: t("lang.es"),
    gl: t("lang.gl"),
    eu: t("lang.eu"),
    en: t("lang.en"),
    ca: t("lang.ca"),
    vc: t("lang.vc"),
    pt: t("lang.pt"),
    fr: t("lang.fr"),
  };

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <>
      <div className="LanguageContainer">
        <select onChange={(event) => handleChangeLanguage(event)}>
          {languages &&
            languages.map((a) => (
              <option value={a} selected={a === language && "selected"}>
                {languagesName[a]}
              </option>
            ))}
        </select>
      </div>
    </>
  );
};

export default SelectLanguagesComponent;
