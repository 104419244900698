import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import "./Ranking.css";
import {
  getFileService,
  getRankingService,
  getBoardRankingsService,
} from "../../../../app/src/services/services";
import RankingDesktopComponent from "./RankingComponents/RankingDesktopComponent";
import RankingMobileComponent from "./RankingComponents/RankingMobileComponent";
import {
  saveUserDataActionCreator,
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import { SWITCHER_WIDTH } from "../../../../app/src/Utils/Constants";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import { getUserDataService } from "../../../../app/src/services/services";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";

const Ranking = () => {
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();

  const userData = useSelector((state) => state.commonState.userData);

  const [rankingData, setRankingData] = useState(null);
  const [renderMobileVersion, setRenderMobileVersion] = useState(false);
  const [userProfile, setUserProfile] = useState({
    firstName: "",
    lastName: "",
    userName: "",
  });
  const [displayCertificate, setDisplayCertificate] = useState(null);

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  const getBoardRankingCallback = (response) => {
    setRankingData((prevState) => ({
      ...prevState,
      rankings: response.ranking,
    }));
  };

  const rankingServiceCallback = (response) => {
    const formattedData = {};
    const achievements = response?.accomplishments?.achievements;
    const rewards = response?.accomplishments?.rewards;
    const closestAchievement = response?.closest_achievement;
    const boardId = response?.consumer_ranking_position?.board?.id;

    formattedData.closestAchievement = closestAchievement;
    formattedData.userRanking = response?.consumer_ranking_position?.ranking;

    if (!!achievements) {
      formattedData.achievements = achievements;
    }

    if (!!rewards) {
      formattedData.rewards = rewards;
    }

    setRankingData((prevState) => ({ ...prevState, ...formattedData }));
    getBoardRankingsService(TENANT, boardId, getBoardRankingCallback);
    turnOffLoading();
  };

  const getFileServiceCallback = (response) => {
    setRankingData((prevState) => ({
      ...prevState,
      userAvatar: response?.data.data,
    }));
  };

  useEffect(() => {
    if (!!userData && !!keycloak && initialized) {
      setUserProfile({
        firstName: userData.firstName,
        lastName: userData.lastName,
        userName: userData.username,
      });

      getFileService(
        TENANT,
        userData.avatar,
        keycloak?.token,
        getFileServiceCallback
      );
      getRankingService(
        TENANT,
        userData.username,
        keycloak?.token,
        rankingServiceCallback
      );

      turnOnLoading();
    }
  }, [userData, keycloak]);

  const getUserDataCallback = (response) => {
    const userData = response?.data;
    setUserProfile({
      firstName: userData.firstName,
      lastName: userData.lastName,
      userName: userData.username,
    });
    dispatch(saveUserDataActionCreator(response));
  };

  useEffect(() => {
    if (!Object.keys(userData)?.length) {
      if (!!keycloak && initialized) {
        const userToken = keycloak?.token;
        const username = keycloak?.tokenParsed?.preferred_username;
        if (!!username) {
          getUserDataService(
            MULTITENANT_CONSTANTS[TENANT].tenantServer,
            username,
            userToken,
            getUserDataCallback
          );
        }
      }
    }
  }, []);

  const handleResize = () => {
    setRenderMobileVersion(window.innerWidth <= SWITCHER_WIDTH);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const toggleCertificateModal = (reward) => {
    if (reward) {
      const data = {
        name: reward.name,
        user: `${userProfile.firstName} ${userProfile.lastName}`,
      };
      setDisplayCertificate(data);
    } else {
      setDisplayCertificate(null);
    }
  };

  return !!MULTITENANT_CONSTANTS[TENANT].gamification ? (
    <div className="Ranking">
      {rankingData !== null && (
        <div className="Ranking-container">
          {renderMobileVersion ? (
            <RankingMobileComponent
              rankingData={rankingData}
              userName={userProfile.userName}
              renderMobileVersion={renderMobileVersion}
              toggleCertificateModal={toggleCertificateModal}
            />
          ) : (
            <RankingDesktopComponent
              rankingData={rankingData}
              userName={userProfile.userName}
              toggleCertificateModal={toggleCertificateModal}
            />
          )}
        </div>
      )}
    </div>
  ) : (
    <Redirect to="/" />
  );
};

export default WithAuthorization(Ranking);
