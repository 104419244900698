import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AlertNotification from "@components/GUI/Alert/Alert";
import RegisterNavigationComponent from "../common/components/RegisterNavigationComponent";

function Conditions(props) {
  const { t } = useTranslation();
  const [aceptadasCU, setAceptadasCU] = useState(false);
  const [aceptadasDatos, setAceptadasDatos] = useState(false);
  const [msgNecesarioAceptar, setMsgNecesarioAceptar] = useState(false);
  const {
    legalConditions,
    msgErroRexistro,
    setMsgErroRexistro,
    ctrlAcceptLegalConditions,
  } = props;

  const handleChange = (e) => {
    setAceptadasCU(e.target.checked);
    setAceptadasDatos(e.target.checked);
  };

  const validar = () => {
    if (aceptadasCU) {
      setMsgErroRexistro(false);
      setMsgNecesarioAceptar(false);
      ctrlAcceptLegalConditions(aceptadasDatos);
    } else {
      setMsgNecesarioAceptar(true);
    }
  };

  return (
    <div className="Conditions">
      <div className="WizardContainer">
        <div className="Descricion">
          <>{t("register.condicionsusotitulo")}</>
          <div className={"subTitle"}>{t("register.textocondicions")}</div>
        </div>
        <div className="CondicionsUso">
          {legalConditions && (
            <div
              className="legal-text"
              dangerouslySetInnerHTML={{ __html: legalConditions }}
            />
          )}
        </div>
        <div className="legal-question">
          <label className="legal-terminos-servicio">
            <input type="checkbox" value={aceptadasCU} onClick={handleChange} />
            {t("legal.aceptocondicions")}
          </label>
        </div>
      </div>

      {msgNecesarioAceptar && (
        <AlertNotification
          type="error"
          showtitle={false}
          text={t("legal.necesarioaceptar")}
        />
      )}
      {msgErroRexistro && (
        <AlertNotification
          type="error"
          showtitle={false}
          text={t("register.errorexistro")}
        />
      )}

      <RegisterNavigationComponent
        handlerBeforePage={props.anteriorPantalla}
        handlerNextPage={validar}
      />
    </div>
  );
}

export default Conditions;
