import React from "react";
import { useTranslation } from "react-i18next";

import "./ErrorPage.css";

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div className={"error-page"}>
      <div className={"text-block"}>
        <h2 className="error-title">{t("error_title")}</h2>
        <h3 className="error-subtitle">{t("error_subtitle")}</h3>
      </div>
    </div>
  );
};

export default ErrorPage;
