import Keycloak from "keycloak-js";
import {KC_BASE_URI, KC_REALM, KC_CLIENT_ID} from "../../Resources/Multitenant/tenantConstants";



export const defaultKeycloak = new Keycloak({
  realm: KC_REALM,
  url: KC_BASE_URI,
  clientId: KC_CLIENT_ID,
});

export var createKeycloak = (url, realm, clientId) =>
  new Keycloak({
    realm: realm,
    url: url,
    clientId: clientId,
    "enable-cors": true,
  });
