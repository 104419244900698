import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import Player from "./Player";
import "./Player.css";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import {URL_LOGIN, URL_PRODUCTS, URL_VIDEODETAIL} from "../../../../app/src/Components/Routes/Location";
import {getContentPermissions} from "../../../../app/src/services/services";
import {TENANT} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {getTokenFromCookie} from "../../../../app/src/Utils/utils";

const PlayerPage = (props) => {
  let {id} = useParams();
  const history = useHistory();
  const {scrollRef} = props;
  const [allowed, setAllowed] = useState(undefined);

  const token = getTokenFromCookie();
  const {keycloak} = useKeycloak({token});

  const contentPermissionsCallback = (response, contentType) => {
    const allowedResponse = response?.allowed;
    if (allowedResponse !== undefined) {
      setAllowed(allowedResponse);
      if (!allowedResponse) history.push(`${URL_PRODUCTS}/${id}`);
    } else {
      history.push(`${URL_LOGIN}`);
    }
  }

  useEffect(() => {
    const userToken = keycloak?.token;
    if(!!id) {
      getContentPermissions(
          TENANT,
          id,
          userToken,
          undefined,
          contentPermissionsCallback
      )
    }
      scrollRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="PlayerPage">
      {!!allowed && <Player theme="vjs-theme-forest" id={id} />}
    </div>
  );
};

export default WithAuthorization(PlayerPage);
