import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";
import RegisterNavigationComponent from "../common/components/RegisterNavigationComponent";

function Category(props) {
  const [validada, setValidada] = useState(false);
  const { t } = useTranslation();

  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [categoriasUnselect, setCategoriasUnselect] = useState([]);
  const [advise, setAdvise] = useState(false);

  const { updateCategory, categorias, datos, setDatosState } = props;

  useEffect(() => {
    let index = "";
    let catsUnselect = [];
    categorias.map((categoryToSelect) => {
      index =
        categoriesSelected?.length > 0 &&
        categoriesSelected
          .map((categorySelected) => categorySelected.name)
          .indexOf(categoryToSelect.name) !== -1;
      if (!index) {
        catsUnselect.push(categoryToSelect);
      }
    });
    setCategoriasUnselect(catsUnselect);
  }, [categoriesSelected, categorias]);

  useEffect(() => {
    if (categoriesSelected?.length === 0) {
      if (!!datos.categorias) {
        setCategoriesSelected(datos.categorias);
      }
    }
  }, [categorias]);

  const validar = () => {
    if (categoriesSelected.length > 0) {
      categoriesSelected.forEach((c) => {
        props.ctrlAddInterest(c);
      });

      setValidada(true);
    } else {
      setAdvise(true);
    }
  };

  const handlerBeforePage = () => {
    setDatosState((prev) => ({ ...prev, categorias: [] }));
    setCategoriesSelected([]);
    props.anteriorPantalla();
  };

  const escollerCategoria = (c) => {
    setAdvise(false);
    let index = categoriesSelected
      ?.map(function (e) {
        return e.name;
      })
      .indexOf(c.name);
    let cats = [...categoriesSelected];
    if (index !== -1) {
      cats.splice(index, 1);
    } else {
      cats = Array.from(new Set([...categoriesSelected, c]));
    }

    if (cats.length > 0) {
      let d = props.datos;
      d.categorias = cats;
      setCategoriesSelected(cats);
      setDatosState((prev) => ({ ...prev, categorias: cats }));
    }
  };

  useEffect(() => {
    if (validada) {
      props.seguintePantalla();
    }
  }, [validada]);

  return (
    <div className="Categoria">
      <div className="WizardContainer">
        <div className="Descricion">{t("register.selectCategory")}</div>
        <Col xs="12" className={"interestsList"}>
          {!!categoriesSelected &&
            categoriesSelected.map((category) => (
              <div
                style={{ backgroundImage: "url(" + category?.image?.url + ")" }}
                className="Interes"
                onClick={() => escollerCategoria(category)}
                key={category.id}
              >
                {category.name}
              </div>
            ))}
        </Col>
        <Col xs="12" className={"interestsList"}>
          {!!categoriasUnselect &&
            categoriasUnselect.map((category) => (
              <div
                className="Interes Unselect"
                onClick={() => escollerCategoria(category)}
                key={category.id}
              >
                {category.name}
              </div>
            ))}
        </Col>
        {!!advise && (
          <div className={"textAlert"}>{t("register.interestAlone")}</div>
        )}
      </div>
      {!updateCategory && (
        <RegisterNavigationComponent
          handlerBeforePage={handlerBeforePage}
          handlerNextPage={validar}
        />
      )}
    </div>
  );
}

export default Category;
