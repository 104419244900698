import * as React from "react";
import { Route } from "react-router-dom";
import MenuApiComponent from "../../Pages/Menu/MenuApiComponent";
import Footer from "../GUI/Footer/Footer";

export function WithMenuRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <MenuApiComponent {...props} {...rest} />
          <div
            className={"WithMenuContainer" + (rest?.nofooter ? " Nofooter" : "") + (rest?.noScroll ? " NoScroll" : "")}
          >
            <Component {...props} {...rest} />
          </div>
          {!rest?.nofooter && <Footer />}
        </>
      )}
    />
  );
}

export function WithMenuRoutePrivate({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <MenuApiComponent {...props} {...rest} />
          <div className={"WithMenuContainer" + (rest?.nofooter ? " Nofooter" : "")}>
            <Component {...props} {...rest} />
          </div>
          {!rest?.nofooter && <Footer />}
        </>
      )}
    />
  );
}
