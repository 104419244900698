import React, { useState } from "react";

import { URL_SEARCH } from "../../../../../Components/Routes/Location";
import { ESCAPE_KEY, SHOW_SEARCH } from "../../../../../Utils/Constants";
import SearchBar from "./SearchBar";

const SearchBarMobile = ({ handelShow, show }) => {
  const isSearchSection = () => {
    return window.location.href.includes(URL_SEARCH);
  };

  const [resetValue, setResetValue] = useState(false);

  const handleDisplaySearchBar = () => {
    handelShow(SHOW_SEARCH);
    setResetValue(true);
  };

  const cancel = (event) => {
    event.key === ESCAPE_KEY && handelShow(SHOW_SEARCH) && setResetValue(true);
  };

  return (
    <>
      <div className={`BarraBusquedaMobil ${isSearchSection() ? "active" : show && "active"}`}>
        <SearchBar resetValue={resetValue} cancel={cancel} focus={show} />
      </div>
      {!isSearchSection() ? (
        show ? (
          <div className={"PecharBarraBusqueda"} onClick={handleDisplaySearchBar}>
            <ion-icon name="close" />
          </div>
        ) : (
          <div className="IconSearhMobile" onClick={handleDisplaySearchBar}>
            <ion-icon name="search-outline" />
          </div>
        )
      ) : null}
    </>
  );
};

export default SearchBarMobile;
