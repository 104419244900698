import React, { useEffect, useState } from "react";
import BarraSuperior from "./components/barraSuperior/BarraSuperior";
import "./Menu.css";
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";

import { LOCAL_STORAGE_KEY__SELECTED_SECTION, SECTION_TYPE_SCREEN, SWITCHER_WIDTH } from "../../Utils/Constants";
import { selectedSectionActionCreator } from "../../actions/commonActions";
import { MULTITENANT_CONSTANTS, TENANT } from "../../Resources/Multitenant/tenantConstants";
import { logUserAnalyticService } from "../../services/services";
import { analyticsConstants, PAGE_VIEW_ACTION, LOGIN_VALUE } from "../../analyticsConstants/analyticsConstants";
import { NotificationsProvider } from "./Context/NotificationsProvider";
import MenuComponent from "./components/menuComponent/MenuComponent";
import { useHistory } from "react-router-dom";
import { URL_NAME_EXCEPTIONS, URL_PATH_NAMES, URL_SCREEN, URL_SECTION } from "../../Components/Routes/Location";
import { generateSlug } from "../../Utils/utils";

const MenuApiComponent = (props) => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const history = useHistory();

  const sectionsMenu = useSelector((state) => state.commonState.sectionsMenu);

  const [renderMobileVersion, setRenderMobileVersion] = useState(null);


  useEffect(() => {
    const username = keycloak?.tokenParsed?.preferred_username;
    const analyticKey = analyticsConstants[PAGE_VIEW_ACTION].key;
    const analyticValue = analyticsConstants[PAGE_VIEW_ACTION].value[LOGIN_VALUE];
    if (!!username) {
      logUserAnalyticService(
        MULTITENANT_CONSTANTS[TENANT].tenantServer,
        username,
        PAGE_VIEW_ACTION,
        analyticKey,
        analyticValue,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setRenderMobileVersion(window.innerWidth <= SWITCHER_WIDTH);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  const handleToggleNotifications = () => {};

  const handleChangeSection = (s) => {
    if (s.type === SECTION_TYPE_SCREEN) {
      localStorage.setItem(LOCAL_STORAGE_KEY__SELECTED_SECTION, JSON.stringify(s));
      dispatch(selectedSectionActionCreator(s));
      if (URL_NAME_EXCEPTIONS.includes(s.name.toUpperCase())) {
        history.push(`/${s.name.toLowerCase()}`);
      } else {
        history.push(`${URL_SCREEN}/${generateSlug(s.name)}/${s.screenId}`);
      }
    } else if (URL_PATH_NAMES.includes(s.type.toUpperCase())) {
      history.push(`/${s.type}`);
    } else {
      history.push(`${URL_SECTION}/${generateSlug(s.name)}`);
    }
  };

  return (
    <div className="Menu">
      {sectionsMenu && (
        <div>
          <BarraSuperior
            sections={sectionsMenu}
            className={`BarraSuperiorUsuario ${
              MULTITENANT_CONSTANTS[TENANT].sticky && "sticky"
            }`}
            handleChangeSection={handleChangeSection}
            {...props}
          />
          <NotificationsProvider>
            <MenuComponent
              handleToggleNotifications={handleToggleNotifications}
              renderMobileVersion={renderMobileVersion}
              handleChangeSection={handleChangeSection}
            />
          </NotificationsProvider>
        </div>
      )}
    </div>
  );
};

export default MenuApiComponent;
