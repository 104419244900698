import React from "react";
import PropTypes from "prop-types";
import iconTVG from "@app/Resources/Images/icono_TVG.png";
import { TVG } from "../../Utils/Constants";
import VideoType from "../../../../detail/src/Pages/DetailPage/VideoType";

const GalleryCardComponent = (props) => {
  const { width, height, image, contentType, tags } = props;

  const galleryCard = {
    margin: "0.2rem",
    paddingLeft: 0,
    listStyle: "none",
    backgroundImage: `url(${image})`,
    width: `${width}px`,
    height: `${height}px`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "0.5rem",
  };

  const subtitle = {
    type: {
      name: contentType,
    },
  };

  return (
    <div className="galleryCard" style={galleryCard}>
      {tags?.size !== 0 && tags?.toArray().includes(TVG) && <img className="iconTVG" src={iconTVG} alt={"iconTVG"} />}
      <VideoType subtitle={subtitle} />
    </div>
  );
};

GalleryCardComponent.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
};

export default GalleryCardComponent;