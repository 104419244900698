import React, { useState, useEffect } from "react";

import "./PointsAnimation.css";
import explosion from "@app/Resources/Images/explosion-puntos.png";

const PointsAnimation = ({ userPoints }) => {
  const [animationChecked, setAnimationChecked] = useState(false);
  const [newUserPoints, setNewUserPoints] = useState(userPoints);

  useEffect(() => {
    setAnimationChecked(true);
    setNewUserPoints(userPoints);
    setTimeout(() => {
      setAnimationChecked(false);
    }, 2000);
  }, [userPoints]);

  return (
    <>
      <label for="checkbox" />
      <input type="checkbox" className="checkbox" id="checkbox" checked={animationChecked} onChange={() => {}} />
      <span className="PointsMenu">{newUserPoints}</span>
      <div
        style={{
          width: "0",
          heigth: "0",
          position: "absolute",
          left: "-10px",
          top: "-28px",
        }}
      >
        {animationChecked && <img src={explosion} alt={"party"} width={80} />}
      </div>
    </>
  );
};

export default PointsAnimation;
