import React from "react";
import PropTypes from "prop-types";

import style from "./style.module.css";
import MetaData from "../MetaData/MetaData";
import MediaCover from "../MediaCover/MediaCover";
import Rating from "../Rating/Rating";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../Resources/Multitenant/tenantConstants";
import {getContentCodeByLanguage, getWidgetSubtitle, getWidgetTitle} from "../../../../Utils/utils";

const PlaylistItem = ({ item, trigger, event, typeBanner }) => {

    const msDateTimeStart = item.start * 1000;

  return (
    <section key={item.id} className={style.playlistItemContainer}>
      <MediaCover data={item} typeBanner={typeBanner} event={event} trigger={trigger} />
      <section className={`${style.playlistItemFooter} ${style.playlistItemFooter__bottomText}`}>
        <MetaData category={getContentCodeByLanguage(item.category)} startTimeMs={msDateTimeStart} seasons={item.seasons} typeBanner={typeBanner} />
          {MULTITENANT_CONSTANTS[TENANT].showRating && <Rating data={item} subtitle={item.subtitle} />}
        <div className={style.playlistItemTitle}>{getWidgetTitle(item)}</div>
        <div className={style.playlistItemSubtitle}>{getWidgetSubtitle(item)}</div>
      </section>
    </section>
  );
};

export default PlaylistItem;

PlaylistItem.propTypes = {
  playlist: PropTypes.array,
};
