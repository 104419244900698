import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/gl";
import {
  addCommentsService,
  updateCommentService,
  getCommentsService,
} from "../../../../app/src/services/services";
import { TENANT } from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {
  DEFAULT_COMMENT_LIMIT,
  DEFAULT_COMMENT_SORT,
  DEFAULT_PAGE,
} from "./CommentsConstants";

import ButtonEllipsis from "@detail/Pages/DetailPage/ButtonEllipsis";

import "./Comments.css";

function Comments(props) {
  const { t } = useTranslation();
  const videoDetailId = props.videoDetailId;
  const [comment, setComment] = useState(null);
  const [commentEdit, setCommentEdit] = useState(null);
  const [defaultCommentPage, setDefaultCommentPage] = useState(DEFAULT_PAGE);
  const [allComments, setAllComments] = useState(props.comments);
  const [moreComments, setMoreComments] = useState(false);

  const [edit, setEdit] = useState(false);
  const [index, setIndex] = useState(false);

  const addCommentCallback = (response) => {
    const updatedComments = [...allComments, response];
    setAllComments(updatedComments);
  };

  const handleclickSend = () => {
    if (comment !== null && comment !== "") {
      addCommentsService(
        TENANT,
        { contentId: videoDetailId, text: comment },
        props.token,
        addCommentCallback
      );
      setComment("");
    }
  };

  const handleclickEdit = (idComment) => {
    if (commentEdit !== null && commentEdit !== "") {
      updateCommentService(
        TENANT,
        idComment,
        { text: commentEdit },
        props.token
      );
    }
    setCommentEdit("");
    setEdit(false);
  };

  const handleSubmit = (event) => {
    if (event.target !== undefined) {
      setComment(event.target.value);
    }
  };

  const handleChangeEdit = (event) => {
    if (event.target !== undefined) {
      setCommentEdit(event.target.value);
    }
  };

  const handleEdit = (index) => {
    setEdit(true);
    setIndex(index);
  };

  const getCommentsCallback = (response) => {
    const newComments = response?.comments;
    setAllComments([...allComments, ...newComments]);
  };

  useEffect(() => {
    allComments.length < props.totalComments
      ? setMoreComments(true)
      : setMoreComments(false);
  }, [allComments]);

  const loadMoreComments = () => {
    const updatedPage = defaultCommentPage + 1;
    setDefaultCommentPage(updatedPage);
    getCommentsService(
      TENANT,
      videoDetailId,
      DEFAULT_COMMENT_LIMIT,
      updatedPage,
      DEFAULT_COMMENT_SORT,
      getCommentsCallback
    );
  };

  return (
    <div className="Comments">
      <div className="Comment2">
        {props.userName && (
          <div className="NewComent">
            <div className="UsuarioComent">
              {props.avatar && (
                <img
                  className="AvatarComent"
                  src={props.avatar}
                  alt="Usuario"
                />
              )}
            </div>

            <div className="ImputComment">
              <input
                className="InputName"
                type="text"
                placeholder={props.placeholder ? props.placeholder : ""}
                onChange={(comment) => handleSubmit(comment)}
                value={comment ? comment : ""}
              />
              <input
                className="BotonVer"
                type="button"
                onClick={handleclickSend}
                value={t("ficha.send")}
              />
            </div>
          </div>
        )}

        {allComments?.map((c) => (
          <div className="CommentsUsers" key={c.id}>
            <div className="TopBarComment">
              <div className="ItemTitle">{c.user}</div>
              <div className="PublishDate">
                {c.publishDate
                  ? moment(c.publishDate)?.format("DD MMM YYYY")
                  : ""}
              </div>
              <div className="button2">
                {props.userName === c.user && (
                  <ButtonEllipsis
                    id={c.id}
                    events={props}
                    editCommmets={handleEdit}
                    className={"EditButton"}
                  />
                )}
              </div>
            </div>

            <div className="ContentComment">
              {(!edit || c.id !== index) && (
                <div className="ItemSubtitle">{c.text}</div>
              )}
              {edit && c.id === index && (
                <div>
                  <div className="ImputCommentEdit">
                    <input
                      className="ItemSubtitleEdit"
                      type="text"
                      onChange={(commentEdit) => handleChangeEdit(commentEdit)}
                      defaultValue={c.text}
                    />
                  </div>

                  <div className="CommentEditButton">
                    <input
                      type="button"
                      className="button button-focused"
                      onClick={(idComment) => handleclickEdit(c.id)}
                      value={t("ficha.edit")}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
        {moreComments ? (
          <div className="ShowMoreButton">
            <input
              type="button"
              className="button-link-secondary"
              onClick={loadMoreComments}
              value={t("ficha.showmore")}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Comments;
