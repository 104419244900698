import desktop_slide_1 from "@resources/Images/guia-escritorio-1.png";
import desktop_slide_2 from "@resources/Images/guia-escritorio-2.png";
import desktop_slide_3 from "@resources/Images/guia-escritorio-3.png";

import mobile_slide_1 from "@resources/Images/guia-mobile-1.png";
import mobile_slide_2 from "@resources/Images/guia-mobile-2.png";
import mobile_slide_3 from "@resources/Images/guia-mobile-3.png";
import mobile_slide_4 from "@resources/Images/guia-mobile-4.png";

import desktop_slide_dark_1 from "@resources/Images/guia-escritorio-dark-1.png";
import desktop_slide_dark_2 from "@resources/Images/guia-escritorio-dark-2.png";
import desktop_slide_dark_3 from "@resources/Images/guia-escritorio-dark-3.png";

import mobile_slide_dark_1 from "@resources/Images/guia-mobile-dark-1.png";
import mobile_slide_dark_2 from "@resources/Images/guia-mobile-dark-2.png";
import mobile_slide_dark_3 from "@resources/Images/guia-mobile-dark-3.png";
import mobile_slide_dark_4 from "@resources/Images/guia-mobile-dark-4.png";

import tiivii_desktop_slide_1 from "@resources/Images/tiivii-guia-escritorio-1.png";
import tiivii_desktop_slide_2 from "@resources/Images/tiivii-guia-escritorio-2.png";
import tiivii_desktop_slide_3 from "@resources/Images/tiivii-guia-escritorio-3.png";

import tiivii_mobile_slide_1 from "@resources/Images/tiivii-guia-mobile-1.png";
import tiivii_mobile_slide_2 from "@resources/Images/tiivii-guia-mobile-2.png";
import tiivii_mobile_slide_3 from "@resources/Images/tiivii-guia-mobile-3.png";

import andromeda_slide_1 from "@resources/Images/andromeda.guia-1.png";
import andromeda_slide_2 from "@resources/Images/andromeda.guia-2.png";
import andromeda_slide_3 from "@resources/Images/andromeda.guia-3.png";

import {
  TENANT_ANDROMEDA,
  TENANT_BOLERUS,
  TENANT_DEFAULT,
  TENANT_PETISGO,
  TENANT_TIIVII,
  TENANT_EUDORA,
} from "../../../Resources/Multitenant/tenantConstants";

export const DEVICE_DESKTOP = "desktop";
export const DEVICE_MOBILE = "mobile";

export const THEME_LIGHT = "kids-web";
export const THEME_DARK = "default-web";

export const ON_BOARDING_SLIDES = {
  [TENANT_PETISGO]: {
    desktop: [
      {
        id: 0,
        img: {
          [THEME_LIGHT]: desktop_slide_1,
          [THEME_DARK]: desktop_slide_dark_1,
        },
        title: "onBoarding.desktop.title.1",
        text: "onBoarding.desktop.text.1",
      },
      {
        id: 1,
        img: {
          [THEME_LIGHT]: desktop_slide_2,
          [THEME_DARK]: desktop_slide_dark_2,
        },
        title: "onBoarding.desktop.title.2",
        text: "onBoarding.desktop.text.2",
      },
      {
        id: 2,
        img: {
          [THEME_LIGHT]: desktop_slide_3,
          [THEME_DARK]: desktop_slide_dark_3,
        },
        title: "onBoarding.desktop.title.3",
        text: "onBoarding.desktop.text.3",
      },
    ],
    mobile: [
      {
        id: 0,
        img: {
          [THEME_LIGHT]: mobile_slide_1,
          [THEME_DARK]: mobile_slide_dark_1,
        },
        title: "onBoarding.mobile.title.1",
        text: "onBoarding.mobile.text.1",
      },
      {
        id: 1,
        img: {
          [THEME_LIGHT]: mobile_slide_2,
          [THEME_DARK]: mobile_slide_dark_2,
        },
        title: "onBoarding.mobile.title.2",
        text: "onBoarding.mobile.text.2",
      },
      {
        id: 2,
        img: {
          [THEME_LIGHT]: mobile_slide_3,
          [THEME_DARK]: mobile_slide_dark_3,
        },
        title: "onBoarding.mobile.title.3",
        text: "onBoarding.mobile.text.3",
      },
      {
        id: 3,
        img: {
          [THEME_LIGHT]: mobile_slide_4,
          [THEME_DARK]: mobile_slide_dark_4,
        },
        title: "onBoarding.mobile.title.4",
        text: "onBoarding.mobile.text.4",
      },
    ],
  },
  [TENANT_TIIVII]: {
    desktop: [
      {
        id: 0,
        img: tiivii_desktop_slide_1,
        title: "onBoarding.tiivii.title.1",
        text: "onBoarding.tiivii.text.1",
      },
      {
        id: 1,
        img: tiivii_desktop_slide_2,
        title: "onBoarding.tiivii.title.2",
        text: "onBoarding.tiivii.text.2",
      },
      {
        id: 2,
        img: tiivii_desktop_slide_3,
        title: "onBoarding.tiivii.title.3",
        text: "onBoarding.tiivii.text.3",
      },
    ],
    mobile: [
      {
        id: 0,
        img: tiivii_mobile_slide_1,
        title: "onBoarding.tiivii.title.1",
        text: "onBoarding.tiivii.text.1",
      },
      {
        id: 1,
        img: tiivii_mobile_slide_2,
        title: "onBoarding.tiivii.title.2",
        text: "onBoarding.tiivii.text.2",
      },
      {
        id: 2,
        img: tiivii_mobile_slide_3,
        title: "onBoarding.tiivii.title.3",
        text: "onBoarding.tiivii.text.3",
      },
    ],
  },
  [TENANT_DEFAULT]: {
    desktop: [
      {
        id: 0,
        img: tiivii_desktop_slide_1,
        title: "onBoarding.tiivii.title.1",
        text: "onBoarding.tiivii.text.1",
      },
      {
        id: 1,
        img: tiivii_desktop_slide_2,
        title: "onBoarding.tiivii.title.2",
        text: "onBoarding.tiivii.text.2",
      },
      {
        id: 2,
        img: tiivii_desktop_slide_3,
        title: "onBoarding.tiivii.title.3",
        text: "onBoarding.tiivii.text.3",
      },
    ],
    mobile: [
      {
        id: 0,
        img: tiivii_mobile_slide_1,
        title: "onBoarding.tiivii.title.1",
        text: "onBoarding.tiivii.text.1",
      },
      {
        id: 1,
        img: tiivii_mobile_slide_2,
        title: "onBoarding.tiivii.title.2",
        text: "onBoarding.tiivii.text.2",
      },
      {
        id: 2,
        img: tiivii_mobile_slide_3,
        title: "onBoarding.tiivii.title.3",
        text: "onBoarding.tiivii.text.3",
      },
    ],
  },
  [TENANT_ANDROMEDA]: {
    desktop: [
      {
        id: 0,
        img: andromeda_slide_1,
        title: "onBoarding.tiivii.title.1.andromeda",
        text: "onBoarding.tiivii.text.1.andromeda",
      },
      {
        id: 1,
        img: andromeda_slide_2,
        title: "onBoarding.tiivii.title.2.andromeda",
        text: "onBoarding.tiivii.text.2.andromeda",
      },
      {
        id: 2,
        img: andromeda_slide_3,
        title: "onBoarding.tiivii.title.3.andromeda",
        text: "onBoarding.tiivii.text.3.andromeda",
      },
    ],
    mobile: [
      {
        id: 0,
        img: andromeda_slide_1,
        title: "onBoarding.tiivii.title.1.andromeda",
        text: "onBoarding.tiivii.text.1.andromeda",
      },
      {
        id: 1,
        img: andromeda_slide_2,
        title: "onBoarding.tiivii.title.2.andromeda",
        text: "onBoarding.tiivii.text.2.andromeda",
      },
      {
        id: 2,
        img: andromeda_slide_3,
        title: "onBoarding.tiivii.title.3.andromeda",
        text: "onBoarding.tiivii.text.3.andromeda",
      },
    ],
  },
  [TENANT_BOLERUS]: {
    desktop: [
      {
        id: 0,
        img: tiivii_desktop_slide_1,
        title: "onBoarding.tiivii.title.1",
        text: "onBoarding.tiivii.text.1",
      },
      {
        id: 1,
        img: tiivii_desktop_slide_2,
        title: "onBoarding.tiivii.title.2",
        text: "onBoarding.tiivii.text.2",
      },
      {
        id: 2,
        img: tiivii_desktop_slide_3,
        title: "onBoarding.tiivii.title.3",
        text: "onBoarding.tiivii.text.3",
      },
    ],
    mobile: [
      {
        id: 0,
        img: tiivii_mobile_slide_1,
        title: "onBoarding.tiivii.title.1",
        text: "onBoarding.tiivii.text.1",
      },
      {
        id: 1,
        img: tiivii_mobile_slide_2,
        title: "onBoarding.tiivii.title.2",
        text: "onBoarding.tiivii.text.2",
      },
      {
        id: 2,
        img: tiivii_mobile_slide_3,
        title: "onBoarding.tiivii.title.3",
        text: "onBoarding.tiivii.text.3",
      },
    ],
  },
  [TENANT_EUDORA]: {
    desktop: [
      {
        id: 0,
        img: tiivii_desktop_slide_1,
        title: "onBoarding.tiivii.title.1",
        text: "onBoarding.tiivii.text.1",
      },
      {
        id: 1,
        img: tiivii_desktop_slide_2,
        title: "onBoarding.tiivii.title.2",
        text: "onBoarding.tiivii.text.2",
      },
      {
        id: 2,
        img: tiivii_desktop_slide_3,
        title: "onBoarding.tiivii.title.3",
        text: "onBoarding.tiivii.text.3",
      },
    ],
    mobile: [
      {
        id: 0,
        img: tiivii_mobile_slide_1,
        title: "onBoarding.tiivii.title.1",
        text: "onBoarding.tiivii.text.1",
      },
      {
        id: 1,
        img: tiivii_mobile_slide_2,
        title: "onBoarding.tiivii.title.2",
        text: "onBoarding.tiivii.text.2",
      },
      {
        id: 2,
        img: tiivii_mobile_slide_3,
        title: "onBoarding.tiivii.title.3",
        text: "onBoarding.tiivii.text.3",
      },
    ],
  },
};
