import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { responsiveGroup } from '@detail/Pages/DetailPage/CarrouselStyle';
import {
  TRANSITION_DURATION,
  TYPE_BANNER_BANNER_LARGE,
} from "../PlaylistConstants";
import defaultBanner from "../../../../../app/src/Resources/Images/default_banner.png";
import "react-multi-carousel/lib/styles.css";
import "@detail/Pages/DetailPage/CarrouselStyle.css";
import { evaluateItemClass, LIMIT_ITEMS } from "./utils";
import {
    actionTypeContainer,
    generateSlug, getContentCodeByLanguage, getWidgetTitle,
    renderImageUrl,
} from "../../../../../app/src/Utils/utils";
import { URL_SCREEN } from "../../../../../app/src/Components/Routes/Location";


const ListScreenComponent = ({
  key,
  className,
  style,
  title,
  hasMoreContents,
  id,
  typeBanner,
  playlist,
  playSpinner,
  trigger,
  titleColor,
  turnOnLoading,
  contentId = null
}) => {
  const [overOnCard, setOverOnCard] = useState(false);
  const {t} = useTranslation()

  const minimumItems = +playlist?.length < LIMIT_ITEMS ? " BlockCarousel" : " ";

    const getPageId = (w) => {
        return w?.screen?.id ? w.screen.id : w.screen;
    }

    return (
        <div className="PlaylistContainer CarouselContainer GroupWidget"  key={key}>
            <div className={"Playlist " + className} style={style}>
                <div className="Titulo" style={{display: "flex"}}>
                    <p style={{width: "auto", color: titleColor}}>{title}</p>
                    {hasMoreContents &&
                        <Link to={`${actionTypeContainer(trigger).ROUTE}/${generateSlug(title)}/${id}`}
                              style={{padding: "0px 0px 20px 0px", display: "flex", width: "120px"}}
                              className="Titulo button-link-secondary">
                            <div className="ShowMoreText">{t('ficha.showmore')}</div>
                            <FontAwesomeIcon icon={faChevronRight} style={{margin: "4px 0 0 5px"}}/>
                        </Link>}
                </div>
                { playlist?.length > 0 &&
                    <Carousel
                        swipeable={true}
                        draggable
                        showDots={false}
                        responsive={responsiveGroup}
                        infinite={false}
                        autoPlay={false}
                        keyBoardControl={false}
                        transitionDuration={TRANSITION_DURATION}
                        customTransition="transform 1000ms ease-in-out 0s"
                        centerMode={false}
                        containerClass={
                            "carousel-container PlaylistCollection " +
                            typeBanner +
                            " " +
                            minimumItems
                          }
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px CardContainer"
                    >
                        {playlist?.map((w, i) => {
                            return <div className={evaluateItemClass({
                                i,
                                overOnCard,
                                playlist,
                                itemClass: "PlaylistItemContainer",
                              })}
                                 key={i} onMouseEnter={()=> setOverOnCard(i)}
                                 onMouseLeave={()=> setOverOnCard(false)}
                            >
                                {!!getPageId(w) &&
                                <Link to={`${actionTypeContainer(trigger).ROUTE}/${generateSlug(w.title)}/${getPageId(w)}`}
                                      onClick={() => contentId !== w.id && turnOnLoading()}
                                      className="PlaylistItem"
                                      key={w.id + "-" + w.backgroundimage + w.color}>
                                    <div className="PlaylistItem">
                                        {(!!w.backgroundimage) &&
                                            <img className="PlaylistItemImage" alt={w.title}
                                                 src={w.backgroundimage}/>
                                        }
                                        {(w.backgroundimage === null) &&
                                            <img className="PlaylistItemImage" src={defaultBanner} alt={w.title}/>
                                        }
                                        <div className="PlaylistItemTitle">{getWidgetTitle(w)}</div>
                                    </div>
                                </Link>}
                            </div>}
                        )}
                    </Carousel>}
            </div>
            {
                playSpinner && <div className="SpinnerContainer">
                    <Spinner animation="border" variant="primary" />
                </div>
            }
        </div>
    )
};

export default ListScreenComponent;
