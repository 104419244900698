import React, { useState, useEffect } from "react";
import AlertNotification from "@components/GUI/Alert/Alert";
import { addFavoriteService, deleteFavoriteService } from "../../../../services/services";
import { TENANT } from "../../../../Resources/Multitenant/tenantConstants";
import { useSelector } from "react-redux";

import style from "./style.module.css";
import { useTranslate } from "../../utils/utils";

const Favorite = (props) => {
  const [actualizado, setActualizado] = useState(null);
  const [isFavorite, setIsFavorite] = useState(null);
  const { t } = useTranslate();
  const userData = useSelector((state) => state.commonState.userData);

  useEffect(() => {
    if (isFavorite != null && isFavorite !== props.isFavorite) {
      setActualizado(true);
    }
    setIsFavorite(props.isFavorite);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const removeFav = () => {
    setActualizado(false);
    deleteFavoriteService(TENANT, userData?.username, props?.id);
  };

  const addFav = () => {
    setActualizado(false);
    addFavoriteService(TENANT, userData?.username, props?.id);
  };

  return (
    <div className={style.favorite}>
      <div className={style.botonFav} onClick={() => (props.isFavorite ? removeFav() : addFav())}>
        {props.isFavorite ? (
          <ion-icon style={{ color: "var(--hoverColor)" }} name={"heart"} />
        ) : (
          <ion-icon name="heart-outline" />
        )}
      </div>
      {actualizado && (
        <AlertNotification
          type="success"
          showtitle={false}
          text={props.isFavorite ? t("ficha.successaddfavorite") : t("ficha.successremovefavorite")}
          isPlaylist={props.isPlaylist}
        />
      )}
    </div>
  );
};

export default Favorite;
