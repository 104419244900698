import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import {
  CONFIRMATION,
  ROL,
  ROL_KID,
  ROL_TEEN,
} from "../../Register/PantallasRexistro";
import SelectLanguagesComponent from "./SelectLanguagesComponent";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
  TENANT_TIIVII,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";

const HeaderRegistrationComponent = ({
  pageNumber,
  rol,
  language,
  languages,
  pantalla,
}) => {
  const [titleByRol, setTitleByRol] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (ROL_KID === rol || ROL_TEEN === rol) {
      setTitleByRol("register.call_your_teacher");
    } else {
      if (TENANT === TENANT_TIIVII) {
        MULTITENANT_CONSTANTS[TENANT].showRegistrationText &&
          setTitleByRol("register.complete_the_form_tiivii_ott");
      } else setTitleByRol("register.complete_the_form");
    }
  }, [rol]);

  return (
    <>
      <h1 className={"Header-Title"}>
        {" "}
        {t("register.register")}
        {!!rol && (
          <span className={"Number-Pages"}>
            <span className={"Page-Actual-Number"}>{pageNumber}</span>/
            {ROL[rol].steps}
          </span>
        )}
      </h1>
      <div className={"languageLine"}>
        <div className={"sectionSubText"}>
          {pantalla !== CONFIRMATION && t(titleByRol)}
        </div>
        <SelectLanguagesComponent language={language} languages={languages} />
      </div>
    </>
  );
};

HeaderRegistrationComponent.propTypes = {
  pageNumber: PropTypes.any,
  totalPages: PropTypes.any,
};

export default HeaderRegistrationComponent;
