import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";

import i18n from "../../../../app/src/i18n";
import {
  URL_LEGAL,
  URL_LOGIN,
  URL_HOME,
} from "../../../../app/src/Components/Routes/Location";
import "./Legal.css";
import AlertNotification from "../../../../app/src/Components/GUI/Alert/Alert";
import {
  TENANT,
  TENANT_PETISGO,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import { THEME_DARK } from "../../../../app/src/Components/common/OnBoarding/OnBoardingConstants";
import { turnOffLoadingActionCreator } from "../../../../app/src/actions/commonActions";
import { updateUserDataService } from "../../../../app/src/services/services";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";

const Legal = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const language = i18n.language;

  const tenantStyles = useSelector((state) => state.commonState.tenantStyles);
  const legal = useSelector((state) => state.commonState.legal);
  const userData = useSelector((state) => state.commonState.userData);

  const [legalText, setLegalText] = useState(null);
  const [aceptadasCU, setAceptadasCU] = useState(false);
  const [aceptadas, setAceptadas] = useState(null);
  const [msgNecesarioAceptar, setMsgNecesarioAceptar] = useState(false);
  const [theme, setTheme] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const translatedLegalText = legal?.translations.find(
      (item) => item.language === language
    );
    setLegalText(translatedLegalText?.text);
  }, [language, legal]);

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  useEffect(() => {
    turnOffLoading();
    localStorage.setItem("OTT-Guest", JSON.stringify(true));
  }, []);

  useEffect(() => {
    if (keycloak) {
      setTheme(keycloak.clientId);
    }
  }, [keycloak]);

  const handleChange = (e) => {
    setAceptadasCU(e.target.checked);
  };

  const updateDataServiceCallback = () => {
    setAceptadas(true);
  };

  const handleSubmit = () => {
    if (aceptadasCU) {
      if (!!userData.username) {
        setMsgNecesarioAceptar(false);
        const body = { acceptedLegalVersion: legal.version };

        updateUserDataService(
          userData.appname,
          userData.username,
          keycloak?.token,
          updateDataServiceCallback,
          undefined,
          body
        );
      } else {
        history.push(`${URL_LEGAL}${URL_LOGIN}${URL_HOME}`);
      }
    } else {
      setMsgNecesarioAceptar(true);
    }
  };

  if (aceptadas) {
    return <Redirect to={`${URL_LEGAL}${URL_LOGIN}${URL_HOME}`} />;
  }

  const backgroundImageByTenant = () => {
    if (
      TENANT !== TENANT_PETISGO ||
      (TENANT === TENANT_PETISGO && theme !== THEME_DARK)
    )
      return `url(${tenantStyles?.backgroundRegister})`;
  };

  return (
    <div
      className="Legal"
      style={{ backgroundImage: backgroundImageByTenant() }}
    >
      <header className="App-header">
        <div className="Logo-Barra" alt="logo" />
      </header>
      <div className="Legal-box">
        <div className="TituloCondicions">
          <h1>{t("register.condicionsusotitulo")}</h1>
        </div>
        <div className="Description">
          <div
            className="legal-text"
            dangerouslySetInnerHTML={{ __html: legalText }}
          />
        </div>
        {msgNecesarioAceptar && (
          <AlertNotification
            type="error"
            showtitle={false}
            text={t("legal.necesarioaceptar")}
          />
        )}
        <div className="legal-question">
          <label className="legal-terminos-servicio">
            <input type="checkbox" value={aceptadasCU} onClick={handleChange} />
            {t("legal.aceptocondicions")}
          </label>
          <button className="NextButton button-focused" onClick={handleSubmit}>
            {t("legal.aceptar")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithAuthorization(Legal);
