import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";

export const hasContentChildren = (content) => {
  return (
    content.hasChildren &&
    !content?.parent?.id &&
    !MULTITENANT_CONSTANTS[TENANT].showOnlySons
  );
};

export const hasNoContentChildren = (content) => {
  return (
    !content.hasChildren &&
    (MULTITENANT_CONSTANTS[TENANT].showOnlySons || !content?.parent?.id)
  );
};
