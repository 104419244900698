import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { evaluateItemClass } from "../utils";
import {
  actionTypeContainer,
  renderImageUrl,
} from "../../../../../../app/src/Utils/utils";
import { TVG } from "../../../../../../app/src/Utils/Constants";
import VideoType from "../../VideoType";
import iconTVG from "@app/Resources/Images/icono_TVG.png";
import { URL_BASE_IMAGE } from "../../../../../../app/src/services/servicesConstants";

const CommonLiveCardComponents = (props) => {
  const {
    overOnCard,
    i,
    w,
    playlist,
    setOverOnCard,
    trigger,
    clickCallback,
    defaultBanner,
  } = props;

  const { t } = useTranslation();

  return (
    <div
      className={evaluateItemClass({
        i,
        overOnCard,
        playlist,
        itemClass: "PlaylistItemContainer",
      })}
      key={w.id}
      onMouseEnter={() => setOverOnCard(i)}
      onMouseLeave={() => setOverOnCard(false)}
    >
      <Link
        to={{
          pathname: actionTypeContainer(trigger)?.ROUTE + "/" + w.id,
        }}
      >
        <div
          className="PlaylistItem Live"
          onClick={clickCallback ? () => clickCallback(w.id) : ""}
          key={w.title}
        >
          <img
            className="PlaylistItemImage"
            src={
              !!w.background || !!w.banner
                ?
                    !!w.banner ?  w.banner :  w.background

                : defaultBanner
            }
            alt={w.title}
          />
          {w.tags?.includes(TVG) && (
            <img className="iconTVG" src={iconTVG} alt={"iconTVG"} />
          )}
          <div className="PlaylistItemTitle">{w.title}</div>
          {!!w?.start && (
            <div className="PlaylistItemSubtitle">
              {t("ficha.timeStart")} {new Date(w.start * 1000).toLocaleString()}
            </div>
          )}
          <div
            className="ButtonAccess"
            onClick={clickCallback ? () => clickCallback(w.id) : ""}
          >
            <div className="Access button-focused">{t("home.access")}</div>
          </div>
        </div>
      </Link>
      <VideoType subtitle={w} />
    </div>
  );
};

export default CommonLiveCardComponents;
