export const URL_BASE = process.env.REACT_APP_URL_BASE;
export const URL_BASE_V2 = process.env.REACT_APP_URL_BASE_V2;
export const URL_BASE_IMAGE = process.env.REACT_APP_URL_BASE_IMAGE;

export const URL_SCEEN = "/screen";
export const URL_SGCA = "sgca/";
export const URL_SERGIO = "sergio/";
export const URL_IDEN = "iden/";
export const URL_ANALYTICS = "analytics/";
export const URL_CAS = "cas/";
export const URL_REM = "reme/";
export const URL_SDP = "sdp/";
export const URL_ORCH = "orch/";
export const URL_GAME_RANKINGS = "game-ranking/";
export const URL_STATS = "stats/";
export const URL_COMM = "comm/";

// SORT TYPES
export const SORT_TYPES = {
  MOST_RECENT_FIRST: "-created_on",
  MOST_POPULAR: "-votes",
  HIGHEST_SCORE: "-valoration",
  TITLE_ALPHABETICAL: "title",
  TITLE_ALPHABETICAL_REVERSED: "-title",
};
